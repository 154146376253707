import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
	ReduxProtectedGenericTable,
	ReduxProtectedPageTop,
} from "../../containers";
import { Pagination } from "../../components";

import { orderExportTableStock } from "./order-export-table-stock";
import { ordersCreators } from "../../_creators";
import { arrayToObject } from "../../utils";
import config from "../../config";
class OrderExportStockPage extends Component {
	constructor(props) {
		super(props);
		this.fetchPage = this.fetchPage.bind(this);
		// this.handleSelectLine = this.handleSelectLine.bind(this);
		// this.handleSelectPage = this.handleSelectPage.bind(this);

		// this.exportSelected = this.exportSelected.bind(this);
		// this.exportAll = this.exportAll.bind(this);
		this.exportOne = this.exportOne.bind(this);

		this.state = {
			orders: [],
			pagination: {},
		};
	}
	componentWillMount() {
		this.fetchPage(1);
	}

	exportOne(line) {
		this.props.exportPdf(line._id).then((res) => {
			console.log(res);
			window.open(res.path);
			this.fetchPage(1);
		});
	}

	fetchPage(page = 1) {
		this.props.fetchOrders(page).then((res) => {
			this.setState({
				orders: res.data,
				pagination: res.pagination,
			});
		});
	}

	render() {
		let { orders, pagination } = this.state;

		pagination = {
			...pagination,
			current: pagination.page,
		};

		let table = orderExportTableStock({
			clients: this.props.clients,
			exportOne: this.exportOne,
			status: config.status,
		});
		return (
			<Fragment>
				<ReduxProtectedPageTop
					actions={[]}
					title="Imprimer les commandes (Stock)"
				/>
				<ReduxProtectedGenericTable
					cols={table.cols}
					data={orders}
				/>
				<Pagination onClick={this.fetchPage} {...pagination} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	clients: state.clients.clients,
});
const mapDispatchToProps = (dispatch) => ({
	fetchOrders: (page) =>
		ordersCreators.fetch(
			{ status: "VALIDATED", type: "MULTIPLE" },
			page
		)(dispatch),
	exportPdf: (orderId) => ordersCreators.exportPdf(orderId)(dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OrderExportStockPage);
