import { generatePassword } from '../utils';
import { alertsActions } from '../_actions';
const initialState = [];

const types = alertsActions.types;

const alertsReducer = (state = initialState, action) => {
    switch(action.type){
        case types.ALERT_PUSH:
            return [
                ...state, 
                ...action.payload.map( (a) => ({
                    id: generatePassword(),
                    seen: false,
                    ...a
                }))
            ];
        case types.ALERT_POP:
            return [...state].filter( (a) => a.id != action.payload.id );
        case types.ALERT_POP_ALL:
            return [];
        case types.ALERT_POP_READ:
            return [...state].filter( (a) => !a.seen );
        case types.ALERT_MARK_READ:
            return [...state].map( (a) => a.id == action.payload.id ? {...a, seen: true} : a );
        case types.ALERT_MARK_READ_ALL:
            return [...state].map( (a) => ({...a, seen: true}) );
        default:
            return state;
    }
}
export default alertsReducer;