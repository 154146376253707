import React, {Component} from 'react';
import { connect } from 'react-redux';
import { checkAuthorization } from '../../_helpers';
import { GenericSidebar } from '../../components';

class ReduxProtectedSidebar extends Component{
    render(){
        let {user, sidebar} = this.props;

        sidebar.sections = sidebar.sections.filter( (section) => {
            if(!section.restricted) return true;

            if(checkAuthorization(user, section.restricted, {})) return true;

            return false;
        }).map( (section) => {
            section.links = section.links.filter( (link) => {
                if(!link.restricted) return false;

                if(checkAuthorization(user, link.restricted, {})) return true;

                return false;
            });
            return section;
        })
        
        return <GenericSidebar sections={sidebar.sections}/>;
    }
}

const mapStateToProps = (state) => ({user: state.auth.user});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxProtectedSidebar);