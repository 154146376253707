import React, { Component } from "react";

import { connect } from 'react-redux';
import { checkAuthorization } from '../../_helpers';
import { PageTop } from '../../layouts';

class ReduxProtectedPageTop extends Component{
    render(){
        let { user, actions, ...rest } = this.props;
        actions = actions.filter( (action) => {
            if(!action.restricted) return true;

            if(action.restricted && checkAuthorization(user, action.restricted, {})) return true;
        });
        return <PageTop actions={actions} {...rest}/>
    }
}
const mapStateToProps = (state) => ({user: state.auth.user});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxProtectedPageTop);