import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ordersService } from '../../_services';

import { ReduxProtectedPageTop, ReduxProtectedGenericTable } from '../../containers';
import { Pagination } from '../../components';

import { arrayToObject, date } from '../../utils';
import { ordersCreators } from '../../_creators';
class OrderExportListPage extends Component{
    constructor(props){
        super(props);
        
        this.fetchPage = this.fetchPage.bind(this);

        this.state = {
            exports: [],
            pagination: {}
        }
        this.table = {
            cols :{
                id: {name: 'id', label: '#ID', content: (line) => line._id.substr(line._id.length - 3, 3)},
                user: {name: 'user',restricted: 'clients:read',label: 'Utilisateur', 
                    content: (line) => {
                        return arrayToObject(this.props.clients, '_id')[line.createdBy].name;
                    }},
                type: {name: 'type', label: 'Type', content: (line) => {
                    return line.path.indexOf('.pdf') > -1 ? 'MULTIPLE' : 'SIMPLE';
                }},
                date: {name: 'date', label: 'Date', content: (line) => date.time.toString(line.createdAt)},
                export: {name: 'export', label: 'Exporter', content: (line) => <a href={line.path} target="_blank" >Exporter</a>},
            }
        }
    }
    componentWillMount(){
        this.fetchPage(1)
    }
    fetchPage(page = 1){
        this.props.fetchExports(page)
        .then((res) => {
            this.setState({
                exports: res.data,
                pagination: res.pagination
            })
        });
    }
    render(){
        let { pagination } = this.state;

        pagination = {
            ...pagination, current: pagination.page
        }

        return (
            <Fragment>
                <ReduxProtectedPageTop actions={[]} title="Historique d'exportation"/>
                <ReduxProtectedGenericTable 
                                fallback={ this.props.loading ? (props) => <div className="spinner-border text-primary"></div> : null }
                                cols={this.table.cols} 
                                data={this.state.exports} />

                <Pagination onClick={ this.fetchPage } {...pagination}/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    clients: state.clients.clients,
    exports: state.orders.exports,
    pagination: state.orders.pagination,
    loading: state.orders.loading
});
const mapDispatchToProps = (dispatch) => ({
    fetchExports: (page = 1) => ordersCreators.fetchExports({}, page)(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderExportListPage);
