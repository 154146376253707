import React, { Component } from "react";
import { Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { checkAbility } from '../../_helpers';

import { Redirect } from "react-router-dom";

class ReduxProtectedRoutes extends Component{
    render(){
        let { user, routes } = this.props;
        return routes.map( ({component: Child, ...route}, index) => {
            return  (
                        <Route 
                                key={index} 
                                {...route} 
                                render={ (props) => 
                                    checkAbility(user, route.restricted, {}) ? <Child {...props}/> : <Redirect to="/login"/>  }/>);
        });
 
    }
}
const mapStateToProps = (state) => ({user: state.auth.user});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxProtectedRoutes);