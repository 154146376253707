import ordersReducer from './orders';
import authReducer from './auth';
import alertsReducer from './alerts';
import clientsReducer from './clients';
import overlayReducer from './overlay';

import { combineReducers } from 'redux';
const rootReducer = combineReducers({
    orders: ordersReducer,
    auth: authReducer,
    alerts: alertsReducer,
    clients: clientsReducer,
    overlay: overlayReducer
});

export default rootReducer;