import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ReduxAlert } from "../../containers";
import { GenericForm } from "../../components";

// import { authActions } from '../../_actions';
import { authCreators } from "../../_creators";
import passwordResetForm from "./passowrd-reset-form";

import "./password-reset.css";
class PasswordReset extends Component {
	constructor(props) {
		super(props);
		this.props.logout();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);

		this.state = {
			form: {
				values: passwordResetForm.defaultValues,
				errors: {},
				error: false,
				loading: false,
				success: this.props.success,
			},
		};
	}

	handleChange(group, input, value) {
		this.setState((state) => {
			let form = { ...state.form };
			if (input == "email") value = value.toLowerCase();
			form.values = GenericForm.updateGroups(form.values, {
				group,
				input,
				value: value.trim(),
			});
			return { form };
		});
	}

	handleBlur(group, input, value) {}
	handleSubmit(e) {
		e.preventDefault();

		let { errors, error } = GenericForm.validateForm(
			this.state.form.values,
			passwordResetForm.validators
		);

		if (errors && Object.keys(errors).length > 0) {
			this.setState(({ form }) => ({
				form: { ...form, errors, error },
			}));
		} else {
			e.preventDefault();

			let email = GenericForm.extractValues(
				{ groups: this.state.form.values },
				passwordResetForm.extractors
			).email;

			this.props.resetPassword(email);
		}
	}
	render() {
		let stateForm = this.state.form;
		return (
			<div className="form-signin">
				<h1 className="h3 mb-3 font-weight-normal">
					Veuillez Insérer Votre Adresse Mail
				</h1>
				<ReduxAlert />
				{this.state.form.success === "success" ? (
					<div className="alert alert-success">
						Verifiez Votre Email
					</div>
				) : (
					""
				)}
				<GenericForm
					groups={passwordResetForm.groups}
					defaultValues={passwordResetForm.defaultValues}
					values={stateForm.values}
					errors={stateForm.errors}
					onChange={this.handleChange}
					onBlur={this.handleBlur}
					onSubmit={this.handleSubmit}
					submit={{
						title: "Réinitialiser le mot de passe",
						className: "btn btn-primary btn-block",
						disabled: this.props.auth.loggingIn,
					}}
				/>

				<small className="mt-5 mb-3 text-muted">
					Attention: en vous connectant, vous accepter les{" "}
					<a
						target="_blank"
						href="https://www.corneliacontactalgerie.com/"
					>
						Condition d'utilisation
					</a>
				</small>
				<p className="mt-5 mb-3 text-muted text-center">
					&copy; Cornelia Contact Algeria 2020 | Designed
					and Developed by{" "}
					<a
						target="_blank"
						href="http://elbeytechnologies.com/"
					>
						ELBEY Technologies
					</a>
				</p>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
	resetPassword: (email) => authCreators.resetPassword(email)(dispatch),
	logout: () => authCreators.logout()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
