import React, { Fragment, Component } from 'react';

import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

import { OrderModalStock, OrderDetails } from '../../components';
import { ReduxProtectedPageTop, ReduxProtectedGenericTable, ReduxAlert } from '../../containers';


import { arrayToObject } from '../../utils';

import { Order } from '../../models';
import './order-add-stock.css';
import debounce from 'lodash.debounce';

import { ordersCreators } from '../../_creators';
class OrderAddStockPage extends Component{
    constructor(props){
        super(props);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.saveModal = this.saveModal.bind(this);

        this.editOrder = this.editOrder.bind(this);
        this.pullOrder = this.pullOrder.bind(this);
        this.pushOrder = this.pushOrder.bind(this);

        this.removeOrder = this.removeOrder.bind(this);
        
        this.saveOrders = debounce(this.saveOrders.bind(this), 1000, {
            leading: true
        });

        this.clearOrders = this.clearOrders.bind(this);
        
 

        this.state = {
            modal: {},
            show_modal: true,
            orders: [],
            groups:[]
        };

        this.table = {
            cols: {
                distributor: {
                    name: 'distributor',
                    label: 'Distributeur',
                    content: (line, index) => {
                        let orderUser = arrayToObject(this.props.clients, '_id')[line.userId];
                        if(orderUser.roles == 'distributor'){
                            return orderUser.name;
                        }
                        return arrayToObject(this.props.clients, '_id')[orderUser.partnerId].name
                    },
                    restricted: 'orders:create:for-client'
                },
                client: {name: "client", label: "Client", 
                    content: (line, index) => {
                        let orderUser = arrayToObject(this.props.clients, '_id')[line.userId];
                            if(orderUser.roles == 'distributor'){
                                return "#";
                            }
                        return orderUser.name
                    }, restricted: 'orders:create:for-client'},
                   
                norm: {name: "norm", label: "norm", content: (line, index) => line.norm},
                details: {name: "details", label: "Details", content: (line, index) => <OrderDetails order={{...line, type: 'MULTIPLE'}} /> },
                qty: {name: "qty", label: "Qty", content: (line, index) => Order.qty({...line, type: 'MULTIPLE'}) },
                
                edit: {name: "edit", label:"Modifier", content: (line, index) => <a href="#" onClick={ (e) => {
                    e.preventDefault();
                    this.editOrder(index)
                } }>Modifier</a>},
                delete: {name: "delete", label:"Supprimer", content: (line, index) => <a onClick={ (e) => {
                    e.preventDefault();
                    this.removeOrder(index);
                } } href="#">Supprimer</a>}
            }
        }

        // document.addEventListener("keydown", (e) => e.keyCode == 27 ? this.closeModal() : null);
    }

    pushOrder(order, index = -1){
        this.setState(({orders}) => {
            orders = [...orders];
            if(index > -1){
                orders.splice(index, 1, order);
            }else{
                orders.push(order);
            }
            return {
                orders
            };
        });
    }
    clearOrders(){
        if(!window.confirm('Etes vous sure?')) return false;
        this.setState({
            orders: []
        });
    }
    editOrder(index){
        this.setState((state) => ({
            modal: {...this.state.orders[index], index},
            show_modal: true
        }));
    }
    removeOrder(index){
        if(window.confirm('Etes vous sur?')){
            this.pullOrder(index);
        }
    }

    pullOrder(index){
        this.setState(({orders}) => {
            orders = [...orders];
            orders.splice(index, 1);
            return {orders};
        })
    }

    openModal(e){
        this.setState({show_modal: true, modal: {}});
    }
    closeModal(e){
        this.setState({show_modal: false, modal: {}})
    }

    async saveModal(order, index = -1){
        // if(Order.qty(order) > 0){
            this.pushOrder(order, index);
        // }else{
        //     window.alert('Votre commande est vide');
        // }
    }
    saveOrders(){
        this.setState({loading: true});
        let orders = this.state.orders.map((o) => Order.clean(o, 'MULTIPLE')).map( (o) => ({...o, type: "MULTIPLE"}) );
        this.props.createOrders(orders)
            .then( (res) => {
                this.setState({
                    orders: [],
                    loading: false
                });
            }).catch((error) => {
                this.setState({loading: false});
            })
    }
    render(){
        let { loading } = this.state;
        return (
            <Fragment>
                <Prompt when={this.state.orders.length > 0} message={ (location) => 'Vous avez des commandes non sauvgarder, etes vous sur?'  }/>
                <ReduxProtectedPageTop 
                        actions={[
                                {text: 'Ajouter', onClick: this.openModal, restricted:'orders:create', color: 'btn-primary'},
                                {text: 'Commander', disabled: loading || this.state.orders.length < 1, 
                                    restricted:'orders:create', onClick: this.saveOrders , color: 'btn-success mr-5'},
                                    {text: 'Vider', onClick: (e) =>  this.clearOrders(), restricted:'orders:create', color: 'btn-danger'},]
                            } 
                         title="Ajouter des commandes (Stock)"/>
                <ReduxAlert />
                <div className="alert alert-warning">
                    Note importante pour nos client: <a href="https://drive.google.com/file/d/1jk5e-vfg566nZBvWFJ5W7BENFy2rBb2d/view?usp=sharing" target="_blank">consulter la note</a>
                </div>
                <OrderModalStock 
                        user={this.props.user}
                        clients={this.props.clients} 
                        onSave={this.saveModal} 
                        onHide={this.closeModal} 
                        order={this.state.modal} 
                        show={this.state.show_modal} />

                <ReduxProtectedGenericTable id="order-add-table" cols={this.table.cols} data={this.state.orders}/>
                <div className="float-right">
                    <button onClick={this.openModal} className="mr-2 btn btn-primary btn-sm">Ajouter</button>
                    <button disabled={loading || this.state.orders.length < 1} onClick={ this.saveOrders } className="mr-5  btn btn-success btn-sm">Commander</button>
                    <button onClick={this.clearOrders} className="btn btn-danger btn-sm">Vider</button>
                </div>
            </Fragment>
        );  
    }
}

const mapStateToProps = (state) => ({ 
    clients: state.clients.clients.filter( (c) => ['client', 'distributor'].indexOf(c.roles) > -1  ),
    orders: state.orders.add_orders,
    user: state.auth.user
 });
const mapDispatchToProps = (dispatch) => ({
    createOrders: (orders) => ordersCreators.create(orders)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderAddStockPage);