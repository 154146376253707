const date = {
    toString: (date) => {
        return `${date.getFullYear()}-${date.getMonth() < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1 }-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`
    },
    fromString: (string) => false,
    time: {
        toString: (datetime) => {
            // datetime = new Date(datetime);
            // return `${datetime.toLocaleString('fr')} ${datetime.toLocaleTimeString('fr')}`
            return (new Date(datetime)).toLocaleString('fr')
        }
    }
}

export default date;