import React from "react";
import { Link } from "react-router-dom";
const TopNavbar = ({ user, onSearchChange, onSearchSubmit, searchq }) => {
	return (
		<nav className="navbar navbar-dark fixed-top bg-dark-blue flex-md-nowrap p-0 shadow">
			<a className="navbar-brand col-sm-3 col-md-2 mr-0" href="#">
				<img src="/rsz_corn.png" />
			</a>

			<form className="form search-form" onSubmit={onSearchSubmit}>
				{/* <!--<p className='search-title'>Recherche</p>-->  */}
				<input
					value={searchq}
					onChange={(e) =>
						onSearchChange(
							e.target.name,
							e.target.value
						)
					}
					name="searchq"
					className="form-control search-input"
					placeholder="Recherche"
					type="text"
				/>
				<button type="submit" class="search-button">
					<img src="/search.png"></img>
				</button>
			</form>
			<ul className="navbar-nav px-3">
				<li className="nav-item text-nowrap">
					<Link to="/login" className="nav-link">
						({user ? user.name : ""}) Déconnexion
					</Link>
				</li>
			</ul>
		</nav>
	);
};

export default TopNavbar;
