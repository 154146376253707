const types = {
    ORDERS_FETCH_REQUEST:'ORDERS_FETCH_REQUEST',
    ORDERS_FETCH_SUCCESS:'ORDERS_FETCH_SUCCESS',
    ORDERS_FETCH_FAILURE:'ORDERS_FETCH_FAILURE',

    ORDERS_PATCH_REQUEST:'ORDERS_PATCH_REQUEST',
    ORDERS_PATCH_SUCCESS:'ORDERS_PATCH_SUCCESS',
    ORDERS_PATCH_FAILURE:'ORDERS_PATCH_FAILURE',

    ORDERS_PATCH_GROUP_REQUEST:'ORDERS_PATCH_GROUP_REQUEST',
    ORDERS_PATCH_GROUP_SUCCESS:'ORDERS_PATCH_GROUP_SUCCESS',
    ORDERS_PATCH_GROUP_FAILURE:'ORDERS_PATCH_GROUP_FAILURE',

    ORDERS_EXPORT_REQUEST:'ORDERS_EXPORT_REQUEST',
    ORDERS_EXPORT_SUCCESS:'ORDERS_EXPORT_SUCCESS',
    ORDERS_EXPORT_FAILURE:'ORDERS_EXPORT_FAILURE',

    ORDERS_EXPORT_ALL_REQUEST:'ORDERS_EXPORT_ALL_REQUEST',
    ORDERS_EXPORT_All_SUCCESS:'ORDERS_EXPORT_All_SUCCESS',
    ORDERS_EXPORT_All_FAILURE:'ORDERS_EXPORT_All_FAILURE',

    ORDERS_CREATE_REQUEST:'ORDERS_CREATE_REQUEST',
    ORDERS_CREATE_SUCCESS:'ORDERS_CREATE_SUCCESS',
    ORDERS_CREATE_FAILURE:'ORDERS_CREATE_FAILURE',

    ORDERS_FETCH_EXPORTS_REQUEST:'ORDERS_FETCH_EXPORTS_REQUEST',
    ORDERS_FETCH_EXPORTS_SUCCESS:'ORDERS_FETCH_EXPORTS_SUCCESS',
    ORDERS_FETCH_EXPORTS_FAILURE:'ORDERS_FETCH_EXPORTS_FAILURE',

    ORDERS_CREATE_PUSH:'ORDERS_CREATE_PUSH',
    ORDERS_CREATE_PULL:'ORDERS_CREATE_PULL',

    ORDERS_CLEAR:'ORDERS_CLEAR',

    ORDERS_EXPORT_PDF_REQUEST:'ORDERS_EXPORT_PDF_REQUEST',
    ORDERS_EXPORT_PDF_SUCCESS:'ORDERS_EXPORT_PDF_SUCCESS',
    ORDERS_EXPORT_PDF_FAILURE:'ORDERS_EXPORT_PDF_FAILURE',

    ORDERS_SEARCH_REQUEST:'ORDERS_SEARCH_REQUEST',
    ORDERS_SEARCH_SUCCESS:'ORDERS_SEARCH_SUCCESS',
    ORDERS_SEARCH_FAILURE:'ORDERS_SEARCH_FAILURE'

};
const ordersActions = {
    types, 
    fetchRequest: () => ({type: types.ORDERS_FETCH_REQUEST}),
    fetchSuccess: (orders) => ({type: types.ORDERS_FETCH_SUCCESS, payload: orders}),
    fetchFailure: () => ({type: types.ORDERS_FETCH_FAILURE}),
    
    searchRequest: () => ({type: types.ORDERS_SEARCH_REQUEST}),
    searchSuccess: (res) => ({type: types.ORDERS_SEARCH_SUCCESS, payload: res}),
    searchFailure: () => ({type: types.ORDERS_SEARCH_FAILURE}),

    createRequest: () => ({type: types.ORDERS_CREATE_REQUEST}),
    createSuccess: () => ({type: types.ORDERS_CREATE_SUCCESS}),
    createFailure: () => ({type: types.ORDERS_CREATE_FAILURE}),

    patchRequest: () => ({type: types.ORDERS_PATCH_REQUEST}),
    patchSuccess: () => ({type: types.ORDERS_PATCH_SUCCESS}),
    patchFailure: () => ({type: types.ORDERS_PATCH_FAILURE}),

    patchGroupRequest: () => ({type: types.ORDERS_PATCH_GROUP_REQUEST}),
    patchGroupSuccess: () => ({type: types.ORDERS_PATCH_GROUP_SUCCESS}),
    patchGroupFailure: () => ({type: types.ORDERS_PATCH_GROUP_FAILURE}),

    exportRequest: () => ({type: types.ORDERS_EXPORT_REQUEST}),
    exportSuccess: () => ({type: types.ORDERS_EXPORT_SUCCESS}),
    exportFailure: () => ({type: types.ORDERS_EXPORT_FAILURE}),

 

    exportAllRequest: () => ({type: types.ORDERS_EXPORT_All_REQUEST}),
    exportAllSuccess: () => ({type: types.ORDERS_EXPORT_All_SUCCESS}),
    exportAllFailure: () => ({type: types.ORDERS_EXPORT_All_FAILURE}),

    exportPdfRequest: () => ({type: types.ORDERS_EXPORT_PDF_REQUEST}),
    exportPdfSuccess: () => ({type: types.ORDERS_EXPORT_PDF_SUCCESS}),
    exportPdfFailure: () => ({type: types.ORDERS_EXPORT_PDF_FAILURE}),

    fetchExportsRequest: () => ({type: types.ORDERS_FETCH_EXPORTS_REQUEST}),
    fetchExportsSuccess: (res) => ({type: types.ORDERS_FETCH_EXPORTS_SUCCESS, payload: res}),
    fetchExportsFailure: () => ({type: types.ORDERS_FETCH_EXPORTS_FAILURE}),

    push: (order, index = -1) => ({type: types.ORDERS_CREATE_PUSH, payload: {order, index}}),
    pull: (index = -1 ) => ({type: types.ORDERS_CREATE_PULL, payload: {index}}),
    clear: () => ({type: types.ORDERS_CLEAR})
};
export default ordersActions;