const types = {
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILURE: "LOGIN_FAILURE",
	LOGIN_REQUEST: "LOGIN_REQUEST",

	LOGOUT_REQUEST: "LOGOUT_REQUEST",
	LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
	LOGOUT_FAILURE: "LOGOUT_FAILURE",

	CLIENTS_RESET_PASSWORD_REQUEST: "CLIENTS_RESET_PASSWORD_REQUEST",
	CLIENTS_RESET_PASSWORD_FAILURE: "CLIENTS_RESET_PASSWORD_FAILURE",
	CLIENTS_RESET_PASSWORD_SUCCESS: "CLIENTS_RESET_PASSWORD_SUCCESS",

	CLIENTS_CHANGE_PASSWORD_REQUEST: "CLIENTS_CHANGE_PASSWORD_REQUEST",
	CLIENTS_CHANGE_PASSWORD_FAILURE: "CLIENTS_CHANGE_PASSWORD_FAILURE",
	CLIENTS_CHANGE_PASSWORD_SUCCESS: "CLIENTS_CHANGE_PASSWORD_SUCCESS",
};

const authActions = {
	types,

	// LOGIN
	loginRequest: () => ({ type: types.LOGIN_REQUEST }),
	loginSuccess: (user, message) => ({
		type: types.LOGIN_SUCCESS,
		payload: user,
	}),
	loginFailure: (message) => ({ type: types.LOGIN_FAILURE, message }),

	// LOGOUT
	logoutRequest: () => ({ type: types.LOGOUT_REQUEST }),
	logoutSuccess: (user, message) => ({
		type: types.LOGOUT_SUCCESS,
		payload: user,
	}),
	logoutFailure: (message) => ({ type: types.LOGOUT_FAILURE, message }),

	// RESET
	clientsResetPasswordRequest: () => ({
		type: types.CLIENTS_RESET_PASSWORD_SUCCESS,
	}),
	clientsResetPasswordSuccess: ({ _id }) => ({
		type: types.CLIENTS_RESET_PASSWORD_SUCCESS,
		payload: { _id },
	}),
	clientsResetPasswordFailure: (err) => ({
		type: types.CLIENTS_RESET_PASSWORD_FAILURE,
		err,
	}),

	// CHANGE
	// RESET
	clientsChangePasswordRequest: () => ({
		type: types.CLIENTS_CHANGE_PASSWORD_SUCCESS,
	}),
	clientsChangePasswordSuccess: () => ({
		type: types.CLIENTS_CHANGE_PASSWORD_SUCCESS,
	}),
	clientsChangePasswordFailure: (err) => ({
		type: types.CLIENTS_CHANGE_PASSWORD_FAILURE,
		err,
	}),
};
export default authActions;
