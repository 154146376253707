import { arrayToObject } from "../utils";
import config from "../config";
import { axios } from "../_helpers/";

const clientsService = {
	fetch: (page = 1, filters = {}) => {
		return axios({
			url: config.routes.clients.get_all,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}).then((res) => {
			let clientsObject = arrayToObject(res.data.data, "_id");
			// this is because the roles is an array in the backend
			res.data.data = res.data.data.map((c) => {
				c = { ...c, roles: c.roles[0] };
				if (
					c.partnerId &&
					c.partnerId != "" &&
					clientsObject[c.partnerId]
				) {
					c.partner = clientsObject[c.partnerId];
				}
				return c;
			});

			return Promise.resolve(res);
		});
	},
	fetchErp: (page = 1, filters = {}) => {
		return axios({
			url: config.routes.clients.get_all,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}).then((res) => {
			let clientsObject = arrayToObject(res.data.data, "_id");
			// this is because the roles is an array in the backend
			res.data.data = res.data.data.map((c) => {
				c = { ...c, roles: c.roles[0] };
				if (
					c.partnerId &&
					c.partnerId != "" &&
					clientsObject[c.partnerId]
				) {
					c.partner = clientsObject[c.partnerId];
				}
				return c;
			});

			return Promise.resolve(res);
		});
	},
	add: (user) =>
		axios({
			url: config.routes.clients.create,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: { ...user, roles: [user.roles] },
				infos: {
					action: "clients:create",
				},
			},
		}),
	delete: (user_id) =>
		axios({
			url: `${config.routes.clients.delete}/${user_id}`,
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: { user_id },
				infos: {
					action: "clients:delete",
				},
			},
		}),
	patch: (user) => {
		if (user.roles && user.roles.length > 0) {
			user.roles = [user.roles];
		}
		return axios({
			url: config.routes.clients.patch,
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: user,
				info: {
					actions: "clients:update",
				},
			},
		}).then((res) => {
			let user = res.data.data;
			user.roles = user.roles[0];
			return Promise.resolve(res);
		});
	},
	invite: ({ email, partnerId }) =>
		axios({
			url: config.routes.clients.invite,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: { email, partnerId },
				info: {
					action: "clients:invite",
				},
			},
		}),

	upload: (formData) =>
		axios.post(config.routes.clients.upload, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			credentials: true,
			onUploadProgress: (progressEvent) => {
				const progress = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				console.log(progress);
			},
		}),
	/*getMedia: () =>
		axios.get(config.routes.clients.getMedia, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			credentials: true,
		}), */
};

export default clientsService;
