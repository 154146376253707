import React, { Component } from 'react';
import { Table } from 'react-bootstrap';



const GenericTable = (props) =>  {
        let { 
            id, cols, data,
            name, className
         } = props;
        return (
            <table id={id} name={name} className={className ? className : "table table-striped"}>
                <TableHeader 
                        cols={ cols } />
                <TableContent 

                        contents={ Object.keys(cols).reduce((acc, cur) => ({...acc, [cur]: cols[cur].content}) ,{}) } 
                        lines={ data }/>
            </table>
     
        );
}


const TableHeader = ({cols}) => {
    return (
        <thead>
            <tr>
                {Object.keys(cols).map( (k, index) => <th  className={cols[k].className} key={index} name={cols[k].name}>{ cols[k].label }</th>)}
            </tr> 
        </thead>
    );
}
const TableContent = ({lines, contents}) => {

    return (
        <tbody>
            {lines.map( (line, i) => <TableRow contents={contents} rowIndex={i} key={i} line={line}/> )}
        </tbody>
    );

}
const TableRow = ({line, contents, rowIndex}) => {
    return (<tr>
        {Object.keys(contents).map( (k, i) => <td  key={i}>{contents[k](line, rowIndex)}</td> ) }
    </tr>);
}
const TableFooter = (props) => {
    return (<tfoot></tfoot>);
}

GenericTable.TableHeader = TableHeader;
GenericTable.TableContent = TableContent;
GenericTable.TableRow = TableRow;

export default GenericTable;
