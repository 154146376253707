import { clientsActions, overlayActions, alertsActions } from "../_actions";
import { clientsService } from "../_services";
import { history } from "../_helpers";
import { arrayToObject } from "../utils";

const clientsCreators = {
	fetch: (page = 1, filters = {}) => (dispatch) => {
		dispatch(clientsActions.clientsFetchRequest());
		return clientsService
			.fetch(page, filters)
			.then((res) => res.data)
			.then((res) => {
				dispatch(clientsActions.clientsFetchSuccess(res.data));
			})
			.catch((err) => {
				clientsActions.clientsFetchFailure(err);
			})
			.then((res) => {});
	},

	fetchErp: (filters = {}) => (dispatch) => {
		dispatch(clientsActions.clientsFetchRequest());
		return clientsService
			.fetchErp(filters)
			.then((res) => res.data)
			.then((res) => {
				dispatch(clientsActions.clientsFetchSuccess(res.data));
			})
			.catch((err) => {
				clientsActions.clientsFetchFailure(err);
			})
			.then((res) => {});
	},
	create: (client) => (dispatch) => {
		dispatch(clientsActions.clientsAddRequest());
		dispatch(alertsActions.clearAll());

		return clientsService
			.add(client)
			.then((res) => res.data)
			.then((res) => {
				dispatch(clientsActions.clientsAddSuccess(res.data));

				dispatch(
					alertsActions.push([
						{
							message: `client "${res.data.name}" est ajouté avec success`,
							type: "success",
						},
					])
				);
				history.push("/client");
			})
			.catch(({ message, errors }) => {
				dispatch(clientsActions.clientsAddFailure(errors));
				// dispatch(alertsActions.push([...errors.map( (e) => ({message: e.message, type: 'danger'}) )]));
			})
			.then((res) => {});
	},
	patch: (client) => (dispatch) => {
		dispatch(clientsActions.clientsPatchRequest());

		return clientsService
			.patch(client)
			.then((res) => res.data)
			.then((res) => {
				dispatch(clientsActions.clientsPatchSuccess(res.data));
				// dispatch(alertsActions.push([{message: 'client was updated', type: 'success'}]));
				// history.push('/client');
			})
			.catch(({ message, errors }) => {
				dispatch(clientsActions.clientsPatchFailure(errors));
				// let errors = err.response.data.error.details; //
				// dispatch(alertsActions.push([...errors.map( (e) => ({message: e.message, type: 'danger'}) )]));
			})
			.then((res) => {});
	},
	delete: (client_id) => (dispatch) => {
		dispatch(clientsActions.clientsDeleteRequest());
		return clientsService
			.delete(client_id)
			.then((res) => res.data)
			.then((res) => {
				dispatch(
					clientsActions.clientsDeleteSuccess(client_id)
				);

				dispatch(
					alertsActions.push([
						{
							message:
								"Client supprimé avec success",
							type: "success",
						},
					])
				);
				history.push("/client");
			})
			.catch((err) => {
				dispatch(clientsActions.clientsDeleteFailure());
				// dispatch(alertsActions.push([{message: 'Client was not deleted', type: 'danger'}]));
				// history.push('/client');
			})
			.then((res) => {});
	},
	resetPassword: (client_email) => (dispatch) => {
		dispatch(clientsActions.clientsResetPasswordRequest());

		return clientsService
			.resetPassword(client_email)
			.then((res) => {
				console.log(client_email);
				console.log("success");
				dispatch(
					clientsActions.clientsResetPasswordSuccess({
						email: client_email,
					})
				);
			})
			.catch((err) => {
				dispatch(clientsActions.clientsResetPasswordFailure());
				console.log(client_email);
				console.log("err");
				console.log(err);
			})
			.then((res) => {});
	},
	invite: (client) => (dispatch) => {
		dispatch(clientsActions.clientsInviteRequest());

		return clientsService
			.invite(client)
			.then((res) => res.data)
			.then((res) => {
				dispatch(clientsActions.clientsInviteSuccess(res.data));
				dispatch(
					alertsActions.push([
						{
							message: "client was invited",
							type: "success",
						},
					])
				);
				// history.push('/client');
			})
			.catch(({ message, errors }) => {
				dispatch(clientsActions.clientsInviteFailure(errors));
				// let errors = err.response.data.error.details; //
				// dispatch(alertsActions.push([...errors.map( (e) => ({message: e.message, type: 'danger'}) )]));
			})
			.then((res) => {});
	},
	upload: (file) => (dispatch) => {
		dispatch(clientsActions.fileUploadRequest());

		return clientsService
			.upload(file)
			.then((res) => res.data)
			.then((res) => {
				dispatch(clientsActions.fileUploadSuccess(res.data));
				dispatch(
					alertsActions.push([
						{
							message: "File was uploaded",
							type: "success",
						},
					])
				);
				// history.push('/client');
			})
			.catch(({ message, errors }) => {
				dispatch(clientsActions.fileUploadFailure(errors));
				// let errors = err.response.data.error.details; //
				// dispatch(alertsActions.push([...errors.map( (e) => ({message: e.message, type: 'danger'}) )]));
			});
	},
	/*getMedia: (dispatch) => {
		dispatch(clientsActions.getMediaRequest());
		return clientsService
			.getMedia()
			.then((res) => res.data)
			.then((res) => {
				return res;
			})
			.catch(({ message, errors }) => {
				return undefined;
			});
	}, */
};

export default clientsCreators;
