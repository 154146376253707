import React from "react";
import { Spinner } from "react-bootstrap";
import "./overlay-background.css";

const OverlayBackground = (props) => {
	return (
		<div id="overlay-background">
			<div>
				<img
					className="cornelia-logo-overlay"
					src="/cornelia_overlay.png"
				/>
			</div>
			<p>Patientez SVP</p>

			<div className="spinner spinner-grow text-dark" role="status">
				<span className="sr-only">Loading...</span>
			</div>
			<div className="spinner spinner-grow text-dark" role="status">
				<span className="sr-only">Loading...</span>
			</div>
			<div className="spinner spinner-grow text-dark" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
};

export default OverlayBackground;
