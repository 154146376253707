import React, { Fragment, Component } from "react";

import { connect } from "react-redux";
import { Prompt } from "react-router-dom";

import { OrderModal, OrderDetails } from "../../components";
import {
	ReduxProtectedPageTop,
	ReduxProtectedGenericTable,
	ReduxAlert,
} from "../../containers";

import { Order } from "../../models";
import { arrayToObject, extractQuery } from "../../utils";

import addOrderForm from "../../components/order-modal/add-order-form";
import { ordersService } from "../../_services";
import debounce from "lodash.debounce";

import "./order-add.css";
import { ordersCreators } from "../../_creators";

import html2pdf from "html2pdf.js";

class OrderAddPage extends Component {
	constructor(props) {
		super(props);

		this.clients = props.users.filter(
			(c) => ["client", "distributor"].indexOf(c.roles) > -1
		);

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.saveModal = this.saveModal.bind(this);

		this.editOrder = this.editOrder.bind(this);
		this.pullOrder = this.pullOrder.bind(this);
		this.pushOrder = this.pushOrder.bind(this);
		this.exportOne = this.exportOne.bind(this);

		this.removeOrder = this.removeOrder.bind(this);

		this.saveOrders = debounce(this.saveOrders.bind(this), 1000, {
			leading: true,
		});

		this.clearOrders = this.clearOrders.bind(this);

		this.orderToPopup = this.orderToPopup.bind(this);

		this.state = {
			modal: {},
			show_modal: true,
			orders: [],
			groups: [],
			loading: false,
		};
		this.table = {
			cols: {
				distributor: {
					name: "distributor",
					label: "Distributeur",
					content: (line, index) => {
						let orderUser = arrayToObject(
							this.clients,
							"_id"
						)[line.userId];
						if (orderUser.roles == "distributor") {
							return orderUser.name;
						}
						return arrayToObject(this.clients, "_id")[
							orderUser.partnerId
						].name;
					},
					restricted: "orders:create:for-client",
				},
				client: {
					name: "client",
					label: "Client",
					content: (line, index) => {
						let orderUser = arrayToObject(
							this.clients,
							"_id"
						)[line.userId];
						if (orderUser.roles == "distributor") {
							return "#";
						}
						return orderUser.name;
					},
					restricted: "orders:create:for-client",
				},

				norm: {
					name: "norm",
					label: "norm",
					content: (line, index) => line.norm,
				},
				details: {
					name: "details",
					label: "Details",
					content: (line, index) => (
						<OrderDetails
							order={{ ...line, type: "SIMPLE" }}
						/>
					),
				},
				qty: {
					name: "qty",
					label: "Qty",
					content: (line, index) =>
						Order.qty({ ...line, type: "SIMPLE" }),
				},
				edit: {
					name: "edit",
					label: "Modifier",
					content: (line, index) => (
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								this.editOrder(index);
							}}
						>
							Modifier
						</a>
					),
				},
				delete: {
					name: "delete",
					label: "Supprimer",
					content: (line, index) => (
						<a
							onClick={(e) => {
								e.preventDefault();
								this.removeOrder(index);
							}}
							href="#"
						>
							Supprimer
						</a>
					),
				},
			},
		};

		document.addEventListener("keydown", (e) =>
			e.keyCode == 27 ? this.closeModal() : null
		);
	}

	orderToPopup(order) {
		let client = arrayToObject(this.clients, "_id")[order.userId];
		let createdBy = arrayToObject(this.props.users, "_id")[
			order.createdBy
		];
		let createdAt = new Date(
			Date.parse(order.createdAt)
		).toLocaleString();

		return `
                ATTENTION une commande avec ces parametre existe deja
                Client: ${client ? client.name : ""} \n
                Date: ${createdAt} \n
                Crée par: ${createdBy ? createdBy.name : ""} \n
                Status: ${order.status} \n
                Details: ${order.norm} ${Order.toString(order).items[0].reduce(
			(acc, s) => `${acc} - ${s}`,
			""
		)}
            `;
	}

	pushOrder(order, index = -1) {
		this.setState(({ orders }) => {
			orders = [...orders];
			if (index > -1) {
				orders.splice(index, 1, order);
			} else {
				orders.push(order);
			}
			return { orders };
		});
	}
	clearOrders() {
		if (!window.confirm("Etes vous sure?")) return false;
		this.setState({
			orders: [],
		});
	}
	editOrder(index) {
		this.setState((state) => ({
			modal: { ...this.state.orders[index], index },
			show_modal: true,
		}));
	}
	removeOrder(index) {
		if (window.confirm("Etes vous sur?")) {
			this.pullOrder(index);
		}
	}
	pullOrder(index) {
		this.setState(({ orders }) => {
			orders = [...orders];
			orders.splice(index, 1);
			return { orders };
		});
	}

	openModal(e) {
		this.setState({ show_modal: true, modal: {} });
	}
	closeModal(e) {
		this.setState({ show_modal: false, modal: {} });
	}

	async saveModal(order, index = -1) {
		// this.setState({loading: true});
		this.setState({ loading: true });
		// forcing the double digits
		order.items = order.items.reduce((acc, item, itemIndex) => {
			item = Object.keys(item).reduce((acc, param, paramIndex) => {
				// Made by yacine, sagital 3 after digits
				if (param.includes("sag")) {
					return {
						...acc,
						[param]:
							param.indexOf("qty") < 0 &&
							param.indexOf("axe") < 0
								? parseFloat(
										item[param]
								  ).toFixed(3)
								: item[param],
					};
				}
				if (param.includes("tor")) {
					return {
						...acc,
						[param]:
							param.indexOf("qty") < 0 &&
							param.indexOf("axe") < 0
								? parseFloat(
										item[param]
								  ).toFixed(0)
								: item[param],
					};
				}
				return {
					...acc,
					[param]:
						param.indexOf("qty") < 0 &&
						param.indexOf("axe") < 0
							? item[param]
							: item[param],
				};
			}, {});
			return [...acc, item];
		}, []);

		if (order.norm.includes("KERA")) {
			if (parseFloat(order.items[0].left_r) >= 7) {
				if (
					!window.confirm(`
                ATTENTION

                Cette lentille va être fabriqué avec une géométrie kératocône.
                
                Voulez-vous continuer ?
                `)
				) {
					this.closeModal();
					this.setState({
						loading: false,
					});
					console.log("Stopped");
					return false;
				}
			}
		}

		if (addOrderForm.shouldCheck(order.norm)) {
			// check if it already exists in the table

			let found = this.state.orders.reduce(
				(acc, o) =>
					acc ||
					btoa(JSON.stringify(o)) ===
						btoa(JSON.stringify(order)),
				false
			);
			if (found) {
				if (
					!window.confirm(
						"Cette commande existe dans le panier, veuillez vous continuer?"
					)
				) {
					this.closeModal();
					this.setState({
						loading: false,
					});
					console.log("Stopped");
					return false;
				}
			} else {
				console.log("not found");
			}

			let { data, statusText } = await ordersService.checkDuplicate(
				extractQuery(Order.clean(order, "SIMPLE"), "check")
			);

			if (data.data.length > 0) {
				if (!window.confirm(this.orderToPopup(data.data[0]))) {
					this.closeModal();
					this.setState({
						loading: false,
					});
					console.log("Stopped");
					return false;
				}
			}
			this.setState({ loading: false });
		} else {
			this.setState({ loading: false });
		}

		this.pushOrder(order, index);
	}

	exportOne(orders) {
		const user_name = sessionStorage.getItem("user_name");
		const today = new Date();
		const options = {
			day: "numeric",
			month: "short",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			hour12: false, // 24-hour format
		};

		const date = today.toLocaleString("fr-FR", options);

		let content =
			"<html><head></head><body><br/><br/> <style> body, html {      margin: 0;    padding: 0;}.table-container {    margin: auto;   width: 100%; }.container {  display: grid;    grid-template-columns: 1fr 1fr; rid-gap: 10px; }table{ margin:auto;width:80%}.section {    text-align: center;    padding: 20px;}  </style> <h1 style='text-align: center;'>Bon de Commande</h1>  <div class='container'>    <div class='section'><p>client : " +
			user_name +
			" </p> </div>    <div class='section'><p> Commandé le : " +
			date +
			"</p><p> Date du document : " +
			date +
			"</p></div></div>  <div class='table-container'>  <table border='1'>        <tr>            <td> ID</td>            <td> Parametres</td>            <td>  Observation  </td>   </tr>        ";

		orders.forEach((order) => {
			const item = order.items[0];

			const leftKeys = Object.keys(item).filter(
				(key) => key.startsWith("left") && key !== "left_qty"
			);

			const leftQty =
				item["left_qty"] === 1 ? [] : [item["left_qty"]];

			let variantes = [
				...leftKeys.map((key) => item[key]),
				...leftQty,
			].join(" ");

			if (item.right_qty > 0) {
				const rightKeys = Object.keys(item).filter(
					(key) =>
						key.startsWith("right") &&
						key !== "right_qty"
				);

				const rightQty =
					item["left_qty"] === 1 ? [] : [item["right_qty"]];

				const rightVariantes = [
					...rightKeys.map((key) => item[key]),
					...rightQty,
				].join(" ");

				variantes = variantes + " <br> " + rightVariantes;
			}

			content += `<tr><td>${order.norm}</td><td>${variantes}</td><td>          </td></tr>`;
		});

		content += "</table></div></body></html>";

		// TODO OPEN PDF HERE

		const pdfOptions = {
			margin: 10,
			filename: "my_pdf.pdf",
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: {
				unit: "mm",
				format: "a4",
				orientation: "portrait",
			},
		};

		// Perform the HTML to PDF conversion

		html2pdf()
			.set(pdfOptions)
			.from(content)
			.outputPdf("dataurlnewwindow");

		html2pdf()
			.set(pdfOptions)
			.from(content)
			.outputPdf((pdf) => {
				// Create a data URL for the PDF
				const pdfDataUrl = URL.createObjectURL(
					new Blob([pdf], { type: "application/pdf" })
				);

				// Open the PDF in a new tab
				window.open(pdfDataUrl, "_blank");
			});
	}

	saveOrders() {
		this.setState({ loading: true });
		// removing the items with right in theme
		let orders = this.state.orders
			.map((o) => Order.clean(o, "SIMPLE"))
			.map((o) => ({ ...o, type: "SIMPLE" }));
		// let orders = this.state.orders.map((o) => Order.clean(o, 'SIMPLE')).map( (o) => ({...o, type: "SIMPLE", return: true, blId: '100', bfId:'300'}) );
		this.props
			.createOrders(orders)
			.then((res) => {
				this.setState({ loading: false });
				if (
					!window.confirm(
						"Voulez vous imprimer la commande?"
					)
				) {
					this.setState({
						orders: [],
					});
				} else {
					// send order instead of order id

					this.exportOne(res.data);

					// let table = document
					// 	.getElementById("order-add-table")
					// 	.cloneNode(true);
					// // Array.from(table.rows).slice(1).sort((a, b) => {
					// //     if(a.cells[2].innerText > b.cells[2].innerText){
					// //         return 1;
					// //     }
					// //     else if(a.cells[2].innerText == b.cells[2].innerText){
					// //         return 0
					// //     }
					// //     return -1;
					// // }).forEach((row) => table.appendChild(row));
					// for (var i = 0; i < table.rows.length; i++) {
					// 	table.rows[i].deleteCell(-1);
					// 	table.rows[i].cells[
					// 		table.rows[i].cells.length - 1
					// 	].innerText = "";
					// 	table.rows[i].cells[
					// 		table.rows[i].cells.length - 1
					// 	].style = "width: 200px;";
					// 	table.rows[i].cells[
					// 		table.rows[i].cells.length - 3
					// 	].style = "width: 200px;";
					// }
					// let textToPrint = `
					// <style>
					// .table, .table tr, .table th, .table td{
					//     border:1px solid black;
					//     padding: 5px;
					// }
					// .order-details ul {
					//     list-style-type: none;
					//     padding-left: 0;
					// }
					// </style>${table.outerHTML}`;

					// let myWindow = window.open("", "", "");
					// if (myWindow) {
					// 	// sometimes the window is blocked
					// 	let date = new Date();
					// 	myWindow.document.write(textToPrint);
					// 	myWindow.document.write(
					// 		`Date: ${date.toLocaleDateString(
					// 			"fr"
					// 		)} ${date.toLocaleTimeString("fr")}`
					// 	);
					// 	myWindow.document.close(); //missing code
					// 	myWindow.focus();
					// 	myWindow.print();
					// }
					this.setState({
						orders: [],
					});
				}
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	}

	render() {
		let { loading } = this.state;
		return (
			<Fragment>
				<Prompt
					when={this.state.orders.length > 0}
					message={(location) =>
						"Vous avez des commandes non sauvgarder, etes vous sur?"
					}
				/>
				<ReduxProtectedPageTop
					actions={[
						{
							text: "Ajouter",
							onClick: this.openModal,
							restricted: "orders:create",
							color: "btn-primary",
						},
						{
							text: "Commander",
							disabled:
								loading ||
								this.state.orders.length < 1,
							restricted: "orders:create",
							onClick: this.saveOrders,
							color: "btn-success mr-5",
						},
						{
							text: "Vider",
							icon: "fa fa-trash",
							onClick: (e) => this.clearOrders(),
							restricted: "orders:create",
							color: "btn-danger",
						},
					]}
					title="Ajouter des commandes"
				/>
				<ReduxAlert />
				<OrderModal
					loading={this.state.loading}
					user={this.props.user}
					onSave={this.saveModal}
					onHide={this.closeModal}
					order={this.state.modal}
					clients={this.clients}
					show={this.state.show_modal}
				/>

				<ReduxProtectedGenericTable
					id="order-add-table"
					cols={this.table.cols}
					data={this.state.orders}
				/>
				<div className="float-right">
					<button
						onClick={this.openModal}
						className="mr-2 btn btn-primary btn-sm"
					>
						Ajouter
					</button>
					<button
						disabled={
							loading ||
							this.state.orders.length < 1
						}
						onClick={this.saveOrders}
						className="btn mr-5  btn-success btn-sm"
					>
						Commander
					</button>
					<button
						onClick={this.clearOrders}
						className="btn btn-danger btn-sm"
					>
						Vider
					</button>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	users: state.clients.clients,
	orders: state.orders.add_orders,
	user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
	createOrders: (orders) => ordersCreators.create(orders)(dispatch),
	exportPdfKeepStatus: (orderId) =>
		ordersCreators.exportPdfKeepStatus(orderId)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderAddPage);
