import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import addOrderStockForm from './add-order-stock-form';

import { GenericForm } from '../../components';

import { ReduxProtectedGenericForm } from '../../containers';

import { Order } from '../../models';

class OrderModalStock extends Component{
    constructor(props){
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onHide = this.onHide.bind(this);

        this.handleChange = this.handleChange.bind(this);
        
        this.state = {
            form: {
                values: addOrderStockForm.defaultValues(this.props.user, {clients: this.props.clients}),
                errors: {},
                loading: false,
                error: false
            }
        };

    }

    componentWillReceiveProps(nextProps){
        if(Object.keys(nextProps.order).length != 0 && nextProps.order != this.props.order){
            this.setState(({form}) => ({index: nextProps.order.index ,form: {...form, values: addOrderStockForm.orderToForm(nextProps.order)}}));
        } 
    }

    handleChange(group, input, value){
        this.setState((state) => {
            let form = {...state.form};    
            form.values = addOrderStockForm.updateGroups(form.values, {group, input, value});
            return {form};
        });
    }

    handleBlur(group, input, value){
        // console.log(input, ' BLURED');
    }
    onHide(){
        this.setState({
            form: {
                values: addOrderStockForm.defaultValues(this.props.user, {clients: this.props.clients}),
                errors: {},
                loading: false,
                error: false
            },
            index: -1
        }, () => this.props.onHide());
        
    }
    onSave(e){
        e.preventDefault();

        let {errors, error} = 
            GenericForm.validateForm(this.state.form.values, 
                    addOrderStockForm.validators(this.props.user, {values: this.state.form.values, clients: this.props.clients}));
        // console.log({errors, error});
        if(errors && Object.keys(errors).length > 0) this.setState(({form}) => ({form: {...form, errors, error}}));

        else {
  
            // this.setState(({form}) => ({form: {...form, errors: {}}}));
            let order = addOrderStockForm.formToOrder(this.state.form.values);
            // check here if order is empty
            if(Order.qty(order) < 1){
                window.alert('Votre commande est vide');
                this.setState(({form}) => ({form: {...form, errors: {}}})) // resseting errors even if we're not saving;
                return false;
            }
            // console.log('Saving...')
            this.props.onSave(
                    order, 
                     this.state.index)
                    .then( () => {
                        let index = this.state.index;
                         if(index == undefined || index == null ||  index < 0){
                        
                            this.setState(({form}) => ({form: {values: 
                                addOrderStockForm.updateGroups(form.values, {group: 'norm_group', input: 'norm', value: form.values.norm_group.norm}), 
                                errors: {}}}));
                        }
                    })
        }
    }

    render(){
        let { show, clients } = this.props;
        let stateForm = this.state.form;
        let form = addOrderStockForm({values: stateForm.values, clients});
        return (
            <Modal onHide={this.onHide} id="order-modal-stock" show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une lentille (Stock)</Modal.Title>
                </Modal.Header>
                <form onSubmit={this.onSave}>
                    <Modal.Body>
                        <ReduxProtectedGenericForm 
                                wrapper='div' 
                                className='row'
                                groups={form.groups}

                                // defaultValues={form.defaultValues}

                                values={stateForm.values}
                                errors={stateForm.errors}


                                onChange={ this.handleChange }
                                onBlur={ this.handleBlur }
                                />
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">Ajouter</Button>
                        <Button type="button" onClick={ this.onHide } variant="secondary">Fermer</Button>
                        {/* <Button onClick={ (e) => {this.onSave(); onHide()} } variant="success">Save & Fermer</Button> */}
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default OrderModalStock;