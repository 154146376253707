import { alertsActions } from '../_actions';

const alertsCreators = {
    push: (alerts) => (dispatch) => {
        dispatch(alertsActions.push(alerts));
    },
    pop: (id) => (dispatch) => {
        dispatch(alertsActions.pop(id));
    },
    clearRead: ()  => (dispatch) => {
        dispatch(alertsActions.clearRead());
    },
    clearAll: ()  => (dispatch) => {
        dispatch(alertsActions.clearAll());
    },
    markRead: (id) => (dispatch) => {
        dispatch(alertsActions.markRead(id));
    },
    markAllRead: () => (dispatch) => {
        dispatch(alertsActions.markAllRead());
    }
}

export default alertsCreators;