import React from 'react';
import { GenericTable, GenericForm } from '../';

const GenericFormTable = (props) => {
    let { cols, data, onChange} = props;
    return (
        <p>GenericFormTable</p>
    );
}

GenericFormTable.updateGroups = GenericForm.updateGroups;

export default GenericFormTable;