const types = {
    ALERT_PUSH : 'ALERT_PUSH',
    ALERT_POP : 'ALERT_POP',
    ALERT_POP_READ : 'ALERT_POP_READ',
    ALERT_POP_ALL : 'ALERT_POP_ALL',
    ALERT_MARK_READ : 'ALERT_MARK_READ',
    ALERT_MARK_READ_ALL : 'ALERT_MARK_READ_ALL',
};

const alertActions = {
    types,
    
    push: (alerts) => ({ type: types.ALERT_PUSH, payload: alerts }),
    pop: (id) => ({ type: types.ALERT_POP,  payload: {id} }),
    clearRead: ()  => ({ type: types.ALERT_POP_READ }),
    clearAll: ()  => ({ type: types.ALERT_POP_ALL }),
    markRead: (id) => ({ type: types.ALERT_MARK_READ, payload: {id} }),
    markAllRead: () => ({ type: types.ALERT_MARK_READ_ALL })
}
export default alertActions;