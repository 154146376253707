import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { clientsCreators, authCreators } from "../../_creators";
import { history } from "../../_helpers";

import { Pagination, GenericTable } from "../../components/";
import {
	ReduxProtectedGenericTable,
	ReduxProtectedPageTop,
	ReduxAlert,
} from "../../containers";
import { generatePassword, arrayToObject } from "../../utils/";

class ClientListPage extends Component {
	constructor(props) {
		super(props);
		this.deleteClient = this.deleteClient.bind(this);
		this.updateClientStatus = this.updateClientStatus.bind(this);
		// this.props.fetchClients();
		this.actions = [
			{
				text: "Ajouter",
				onClick: (e) => history.push("/client/add"),
				restricted: "clients:create",
				color: "btn-primary",
			},
			{
				text: "Inviter",
				onClick: (e) => history.push("/client/invite"),
				restricted: "clients:invite",
				color: "btn-info",
			},
		];
		this.table = {
			cols: {
				client_id: {
					name: "client_id",
					label: "#ID",
					content: (line) =>
						line._id.substr(line._id.length - 3, 3),
				},
				name: {
					name: "name",
					label: "Nom",
					content: (line) => line.name,
				},
				c_code: {
					name: "c_code",
					label: "Code",
					content: (line) => line.c_code,
				},
				status: {
					name: "status",
					label: "Status",
					content: (line) => line.status,
				},
				roles: {
					name: "roles",
					label: "Role",
					content: (line) => line.roles,
				},
				distributer: {
					name: "distributer",
					label: "Distributeur",
					content: (line) => {
						if (
							line.partnerId &&
							line.partnerId != ""
						) {
							let dist = arrayToObject(
								this.props.clients,
								"_id"
							)[line.partnerId];
							if (dist && dist.name)
								return dist.name;
						}
						return "#";
					},
				},
				email: {
					name: "email",
					label: "Email",
					content: (line) => line.email,
				},
				reset: {
					name: "reset",
					label: "Password",
					content: (line) => (
						<a
							onClick={(e) => {
								e.preventDefault();
								this.resetPassword(line.email);
							}}
							href="#"
						>
							Ré-initialiser
						</a>
					),
					restricted: "clients:reset-password",
				},
				edit: {
					name: "edit",
					label: "Modifier",
					content: (line) => (
						<Link
							to={{
								pathname: `/client/${line._id}/edit`,
								state: { line },
							}}
						>
							Modifier
						</Link>
					),
					restricted: "clients:update",
				},
				delete: {
					name: "delete",
					label: "Supprimer",
					content: (line) => (
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								this.deleteClient(line);
							}}
						>
							Supprimer
						</a>
					),
					restricted: "clients:delete",
				},
				enable: {
					name: "enable",
					label: "Status",
					content: (line) => {
						if (
							["PENDING", "DISACTIVATED"].indexOf(
								line.status
							) > -1
						) {
							return (
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										this.updateClientStatus(
											line._id,
											"ACTIVATED"
										);
									}}
								>
									Activer
								</a>
							);
						}
						return (
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();
									this.updateClientStatus(
										line._id,
										"DISACTIVATED"
									);
								}}
							>
								Désactiver
							</a>
						);
					},
					restricted: "clients:update-status",
				},
			},
		};
		this.state = {
			page: 1,
		};
	}
	deleteClient(user) {
		// return console.log(user_id);
		let message = window.prompt("SVP re-enter l'email du client");
		if (message && message.trim() == user.email) {
			this.props.deleteClient(user._id);
		} else {
			alert("Email erroné");
		}
	}
	updateClientStatus(_id, status) {
		if (window.confirm("Are you sure?")) {
			this.props.updateClientStatus(_id, status);
		}
	}
	resetPassword(email) {
		if (window.confirm("Are you sure?")) {
			this.props.resetPassword(email);
		}
	}
	render() {
		const pagination = {
			per_page: 10,
			total_pages: Math.ceil(this.props.clients.length / 10),
			current: this.state.page,
			total: this.props.clients.length,
		};
		console.log(pagination);
		return (
			<Fragment>
				<ReduxProtectedPageTop
					actions={this.actions}
					title="Liste des clients"
				/>
				<ReduxAlert />
				<ReduxProtectedGenericTable
					cols={this.table.cols}
					data={[...this.props.clients].splice(
						(pagination.current - 1) *
							pagination.per_page,
						pagination.per_page
					)}
				/>

				<Pagination
					onClick={(page) => this.setState({ page })}
					{...pagination}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	clients: state.clients.clients,
	pagination: state.pagination,
});
const mapDispatchToProps = (dispatch) => ({
	fetchClients: (page = 1, filters = {}) =>
		clientsCreators.fetch(page, filters)(dispatch),
	deleteClient: (_id) => clientsCreators.delete(_id)(dispatch),
	resetPassword: (email) =>
		authCreators.resetPassword(email, true)(dispatch),
	updateClientStatus: (_id, status) =>
		clientsCreators.patch({ _id, status })(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientListPage);
