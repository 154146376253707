import React from 'react';
import wilayas from './wilayas.json';
// console.log( JSON.stringify(wilayas.map( (w) => ({label: w.value + ' - ' + w.label, value: w.value}) )) )
const halfCol = {tag: 'div', attrs: {className: 'col-sm-6'}}
const addClientForm = (clients) => {
    return {
        name: "addClientForm",
        id: "addClientForm",
        actions: "",
        method: "",
        groups: {
            "client_details":{
                name: 'client_details',
                wrapper: {tag: 'div', attrs: {className: 'row'}},
                inputs: {
                    "name": {wrapper: halfCol, name: 'name',  label: 'Nom', type: 'text' },
                    "email": {wrapper: halfCol, name: 'email',  label: 'Email', type: 'text'  },
                    "c_code": {wrapper: halfCol, name: 'c_code',  label: 'Code client', type: 'text'  },
                    "roles": {wrapper: halfCol, name: 'roles',  label: 'Role', type: 'select', values: ['admin', 'distributor', 'client', 'moderator'] },
                    "partnerId": {wrapper: halfCol, name: 'partnerId', label: 'Distributeur', type: 'select', values: [{ label: 'None', value: '' }, ...clients.map((c) => ({ label: c.name, value: c._id }))] },
                    "password": {wrapper: halfCol, name: 'password', label: 'Mot de passe', type: 'text' },
                    // "generate": { name: 'generate', label: ((props) => <small>&nbsp;</small>)(), className: "btn btn-info", type: 'button', text: 'Generer' },
                    "phone": {wrapper: halfCol, name: 'phone',  label: 'Tel', type: 'text' },
                    "address": {wrapper: halfCol, name: 'address',  label: 'Adresse', type: 'text' },
                    "city": {wrapper: halfCol, name: 'city',  label: 'Wilaya', type: 'select', values: [{value: '', label: 'Choisir une wilaya'}, ...wilayas] },
                }
            }
        },
    };
}

addClientForm.errorsFromBackend = (errs) => {
    let errors = {};
    let groups = {
        client_details: ["name", "email", "c_code", "roles", "partnerId", "password", "phone", "address", "city"]
    };
    return Object.keys(groups).reduce((newGroups, groupIndex) => {
        let group = groups[groupIndex];

        return {...newGroups, [groupIndex]: group.reduce( (acc, i) => {
            if(errs[i]){
                return {...acc, [i]: errs[i]}
            }
            return acc;
        }  , {})};
    }, {});
};
addClientForm.validators = (values) => {
    return {
        client_details:{
            name: [
                (value) => value && value != '' ? false : 'Vous devez remplir le nom'
            ],
            email: [
                (value) => value && value.length > 0 && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ? false : "Vous devez remplir ce champ avec un email valid",
            ],
            c_code: [
                (value) => value && value.length > 0 ? false : 'Vous devez remplir le code client'
            ],
            roles: [
                (value) => value && value.length > 0 && ['admin', 'distributor', 'client', 'moderator'].indexOf(value) > -1 ? false : "Vous devez choisir un role"
            ],
            partnerId: [
                (value) => {
                        if(values.client_details.roles == 'client' && (!value || value && value.length < 1)) return 'Vous devez choisir un distributor';
                        else if(values.client_details.roles != 'client' && (value && value.length > 0)) return 'Le role choisis ne peut pas avoir un distributor';
                        return false;
                    }
                ],
            password: [
                (value) => value && value.length >= 8 ? false : 'Le mot de passe doit être de 8 caractères minimum'
            ],
            city: [
                (value) => value && wilayas.map((w) => w.value).indexOf(value) > -1 ? false : 'Vous devez choisir une wilaya'
            ]
        }
    };
};
addClientForm.extractors = {
    client_details:{
        value: (group) => {
            let res = {...group};
            if(['client'].indexOf(group.roles) < 0 ) delete res.partnerId;
            return res;
        },
        inputs: {}
    }
};
addClientForm.defaultValues = {
    client_details:{
        name: '',
        email: '',
        partnerId: '',
        password: '',
        phone: '',
        address: '',
        city: '16',
        c_code: '',
        roles: 'client',
    }
};
export {
    addClientForm
}