import { ordersActions } from "../_actions"
const initialState = {
    loading: false,
    pagination: {},
    orders: [],
    add_orders: [],
    exports: []
}
const types = ordersActions.types;
const ordersReducer = (state = initialState, action) => {
    return state;
}
export default ordersReducer;