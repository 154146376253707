import React, {Component} from 'react';
import { connect } from 'react-redux';
import { checkAuthorization } from '../../_helpers';
import { GenericForm } from '../../components';

class ReduxProtectedGenericForm extends Component{
    static updateGroups(oldGroups, newGroup){
        return GenericForm.updateGroups(oldGroups, newGroup);
    }
    render(){

        let {user, groups, ...props} = this.props;

        // Restricting groups
        groups = Object.keys(groups).reduce( (acc, group) => {
            group = groups[group];

            if(group.restricted && !checkAuthorization(user, group.restricted, {})) return acc;
            
            return {...acc, [group.name]: group}
            
        }, {});
 
        // Restricting inputs in the result groups 
        groups = Object.keys(groups).reduce( (acc, group) => {
            group = groups[group];

            group.inputs = Object.keys(group.inputs).reduce((inputs, input) => {
                input = group.inputs[input];

                if(input.restricted && !checkAuthorization(user, input.restricted, {})) return inputs;
                return {...inputs, [input.name]: input}
            }, {});

            // Return the group with the new inputs
            return {...acc, [group.name]: group};
        }, {});      
        
        

        return (
            <GenericForm 
                groups={groups}
                {...props} 
            />
        )
    }
}

const mapStateToProps = (state) => ({user: state.auth.user});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxProtectedGenericForm);