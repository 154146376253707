import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import config from "../../config";
import {
	ReduxProtectedGenericTable,
	ReduxProtectedPageTop,
	ReduxAlert,
} from "../../containers";

import { Pagination, OrdersFilters, GenericForm } from "../../components";
import { orderFiltersForm } from "../../components/order-filters/order-filters-form";
import { history } from "../../_helpers";
import { orderSearchTable } from "./order-search-table";

import form from "../../components/order-modal/add-order-form";

import "./order-search.css";

import { ordersCreators } from "../../_creators";
import { arrayToObject } from "../../utils";
import { Order } from "../../models";

class OrderSearchPage extends Component {
	constructor(props) {
		super(props);

		// this.validateOrder = this.validateOrder.bind(this);

		this.handleSelectLine = this.handleSelectLine.bind(this);
		this.handleSelectPage = this.handleSelectPage.bind(this);

		this.handleStatusChange = this.handleStatusChange.bind(this);

		this.rejectOrder = this.rejectOrder.bind(this);
		this.cancelOrder = this.cancelOrder.bind(this);

		this.fetchPage = this.fetchPage.bind(this);
		// this.applyFilters = this.applyFilters.bind(this);

		this.actions = [
			{
				text: "Ajouter",
				onClick: (e) => history.push("/order/add"),
				restricted: "orders:create",
			},
			{
				text: "Exporter",
				onClick: (e) => history.push("/order/export"),
				restricted: "orders:export",
			},
		];

		this.state = {
			...orderFiltersForm.defaultValues(), // will give filters:{}
			orders: [],
			pagination: {},
			searchq: this.props.match.params.searchq,
			// selected_orders: [],
			selected_status: "VALIDATED",
		};
	}

	componentDidMount() {
		this.fetchPage(1);
	}
	componentWillReceiveProps(nextProps) {
		if (
			this.props.match.params.searchq !=
			nextProps.match.params.searchq
		) {
			this.setState(
				{
					searchq: nextProps.match.params.searchq,
				},
				() => {
					this.fetchPage(1);
				}
			);
		}
		return true;
	}
	getFilters(search) {
		//console.log({...Order.extractQuery(search, 'search'), type: 'SIMPLE'});
		console.log({ search, type: "SIMPLE" });
		return {
			query: btoa(
				JSON.stringify({
					search,
					type: "SIMPLE",
					isSearch: true,
				})
			),
			base64: true,
		};
	}
	fetchPage(page = 1) {
		let search = Order.extractSearch(
			this.props.clients[0]["_id"],
			this.state.searchq
		);

		if (search) {
			this.props
				.fetchOrders(this.getFilters(search), page)
				.then((res) => {
					this.setState({
						orders: res.data.map((o) => ({
							...o,
							selected: false,
						})),
						pagination: res.pagination,
					});
				});
		} else {
			alert("Le format n'est pas conforme au regles");
		}
		return;
	}

	handleSelectLine(index, _id, selected) {
		console.log(index, _id, selected);
		this.setState(({ orders }) => {
			orders = [...orders];
			orders[index] = { ...orders[index], selected };
			return { orders };
		});
	}

	handleStatusChange(value) {
		this.setState((state) => ({ selected_status: value }));
	}

	rejectOrder(_id) {
		let message = window.prompt("SVP Ajouter un commentaire");
		if (message != null) {
			this.props.rejectOrder(_id, message).then((res) => {
				this.fetchPage(1);
			});
		}
	}

	cancelOrder(_id) {
		if (window.confirm("Etes vous sur?")) {
			this.props.cancelOrder(_id).then((res) => {
				this.fetchPage(1);
			});
		}
	}

	handleSelectPage(selected) {
		this.setState(({ orders }) => {
			orders = [...orders].map((o) => ({ ...o, selected }));
			return { orders };
		});
	}

	patchGroup(status = null) {
		status = status ? status : this.state.selected_status;

		let orders = this.state.orders
			.filter(({ selected }) => selected)
			.map(({ _id }) => ({ _id, status }));

		this.props.patchOrdersGroup(orders).then(() => {
			this.fetchPage(1); // fetch a new list with the applied filters
		});
	}

	render() {
		let { pagination, orders } = this.state;

		let table = orderSearchTable({
			status: config.status,
			clients: arrayToObject(this.props.clients, "_id"),
			onSelectLine: this.handleSelectLine,
			onSelectPage: this.handleSelectPage,
			isPageSelected:
				this.state.orders.filter(({ selected }) => selected)
					.length == this.state.orders.length,
			onReject: this.rejectOrder,
			onCancel: this.cancelOrder,
		});

		pagination = {
			...pagination,
			current: pagination && pagination.page ? pagination.page : 1,
		};

		return (
			<Fragment>
				<ReduxProtectedPageTop
					actions={this.actions}
					title="Trouver une commande"
				/>
				<ReduxAlert />
				{/* 
                <OrdersFilters 
                        onSave={ this.applyFilters } 
                        filters={this.state.filters} 

                        // disabled={this.props.loading}

                        clients={this.props.clients}/>               */}

				<ReduxProtectedGenericTable
					cols={table.cols}
					data={orders}
				/>

				<div id="bottom-page">
					<div className="float-left" id="group-actions">
						<div className="row">
							<div className="col">
								<select
									defaultValue={
										this.state
											.selected_status
									}
									onChange={(e) =>
										this.handleStatusChange(
											e.target.value
										)
									}
									name="status"
									className="form-control form-control-sm"
								>
									{Object.keys(
										config.status
									).map((s, index) => (
										<option
											key={index}
											value={s}
										>
											{
												config
													.status[
													s
												]
											}
										</option>
									))}
								</select>
							</div>
							<div className="col">
								<button
									disabled={
										orders.filter(
											({
												selected,
											}) => selected
										).length < 1
									}
									onClick={(e) =>
										this.patchGroup()
									}
									className="btn btn-info btn-sm"
								>
									Patcher
								</button>
							</div>
						</div>
					</div>

					<div className="float-right">
						<Pagination
							onClick={this.fetchPage}
							{...pagination}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state) => ({
	clients: state.clients.clients.filter(
		(c) => ["client", "distributor"].indexOf(c.roles) > -1
	),
});
const mapDispatchToProps = (dispatch) => ({
	fetchOrders: (filters = {}, page = 1) =>
		ordersCreators.fetch(filters, page)(dispatch),
	patchOrdersGroup: (orders) => ordersCreators.patchGroup(orders)(dispatch),
	rejectOrder: (_id, comment) =>
		ordersCreators.patch({ _id, status: "REJECTED", comment })(
			dispatch
		),
	cancelOrder: (_id) =>
		ordersCreators.patch({ _id, status: "CANCELED" })(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSearchPage);
