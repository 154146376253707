import config from "../config";

import { axios, userStorage } from "../_helpers/";

const authService = {
	login: (user) =>
		axios({
			url: config.routes.auth.login,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: user,
				infos: {
					action: "auth:login",
				},
			},
		}),
	logout: () =>
		new Promise((resolve, reject) => {
			try {
				userStorage.unset();
				resolve({ message: "logged out with success" });
			} catch (e) {
				reject("Couldnt logout");
			}
		}),

	resetPassword: (user_email) =>
		axios({
			url: config.routes.clients.resetPassword,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: { email: user_email },
				info: {
					action: "clients:reset-password",
				},
			},
		}),
	changePassword: (password, token) =>
		axios({
			url: config.routes.clients.changePassword,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: {
					password: password.password,
					confirmPassword: password.confirmPassword,
					token: token,
				},
				info: {
					action: "clients:change-password",
				},
			},
		}),
};
export default authService;
