// type can be search, check

const extractQuery = (order, type) => {
    let query = {};
    let items = order.items;
    if(type == 'search'){
        // dont forget client code
        query = {
            norm: order.norm,
            $or: ['left', 'right'].map( (side) => Object.keys(items[0]).reduce((acc, p) => ({...acc, [`items.${side}_${p}`]:  items[0][p]}) , {}))
        };
        if(order.client && order.client.length > 0) query['user.c_code'] = order.client;
        
    }else if(type == 'check'){
        query = {
            norm: order.norm,
            $or: []
        };
        if(order.userId && order.userId.length > 0) query.userId = order.userId; 
        items.push({
            ...Object.keys(items[0]).filter((i) => i.indexOf('left') > -1).reduce((acc, i, index) => ({...acc, [i.replace('left', 'right')]: items[0][i] }),{} ),
            ...Object.keys(items[0]).filter((i) => i.indexOf('right') > -1).reduce((acc, i, index) => ({...acc, [i.replace('right', 'left')]: items[0][i] }),{} )
        })
        
        query.$or = items.map( (i) => {
            return Object.keys(i).reduce((acc, p, index) => {
                if(p.indexOf('qty') > -1 ) return acc;
                return {...acc, [`items.${p}`]: i[p]};
            }, {});
        });
        return query;
        // suppose there's only one side
        // let present_sides = ['left'];

        // if(order.items[0].right_qty && order.items[0].right_qty != '') present_sides.push('right');

            
        // let current = items.left_qty && items.left_qty != 0 ? 'left' : 'right';
        // query = {
        //     norm: order.norm,
        //     $or: ['left', 'right'].map( (side) => Object.keys(items).reduce((acc, p) => {
        //         if (p.indexOf('qty') > -1) return acc;
        //         if(p.indexOf(current)){
        //             return ({...acc, [`items.${p}`]: items[p]})
        //         }else{
        //             p = p.split(`${current}_`)[1];
        //             return ({...acc, [`items.${side}_${p}`]:  items[`${current}_${p}`]})
        //         }
        //     } , {}))
        // }
        // if(order.client && order.client.length > 0) query.userId = order.client;
    }
    return query;
}

export default extractQuery;