import React from 'react';
import { Order } from '../../models';
import { date, arrayToObject } from '../../utils';
const orderExportTable = ({status, clients, onSelectLine, selectPage, isPageSelected, exportOne}) => {
    return {
        cols: {
            selected: {
                    name: 'selected', 
                    label: ((props) => <input checked={isPageSelected}  type="checkbox" onChange={ (e) =>  selectPage(e.target.checked) } />)(),
                    content: (line, index) => <input checked={line.selected} type="checkbox" onChange={ (e) => onSelectLine(index, line._id, e.target.checked)  } />
                },
            order_id: {name: "order_id", label: "#ID", content: (line) => line._id.substr(line._id.length - 3, 3) },
            
            distributor: {name: "distributor", label: "Distributeur", 
            content: (line, index) => {
                let orderUser = arrayToObject(clients, '_id')[line.userId];
                if(!orderUser) return '#';
                if(orderUser.roles == 'distributor'){
                    return orderUser.name;
                }
                return arrayToObject(clients, '_id')[orderUser.partnerId].name
            }, restricted: 'clients:read'},

            client: {name: "client", label: "Client", 
                content: (line, index) => {
                    let orderUser = arrayToObject(clients, '_id')[line.userId];
                    if(!orderUser) return '#';
                        if(orderUser.roles == 'distributor'){
                            return "#";
                        }
                    return orderUser.name
                }, restricted: 'clients:read'},
            
            norme: {name: 'norme', label: 'Norme', content: (line) => line.norm},
            details: {className: 'details-cell' ,name: 'details', label: 'Details', content: (line) => <p>Details</p>},
            qty: {name: 'qty', label: 'Qty', content: (line) => Order.qty({...line, type: 'SIMPLE'})},
            status: {name: 'status', label: 'Etat', content: (line) => status[line.status]},
            date: {name: 'date', label: 'Date', content: (line) => date.time.toString(line.createdAt)},
            export : {name: 'export', label: 'Exporter', content: (line) => <button onClick={ (e) => exportOne(line) } className="btn btn-sm btn-info">Exporter</button>}
        }
    }
}

export {
    orderExportTable
}