// CREATE
// READ
// UPDATE
// DELETE
const rules = {
    admin: {
        'dashboard:load': true,
        'login-page:visit': true,
        
        'orders:read': true,
        
        'return:create': true,

        'orders:create': true,
        'orders:create:for-client': true,

        'orders:update': true,
        'orders:validate': true,
        'orders:reject': true,
        
        'orders:export:create': true,
        'orders:export:read': true,
        
        'clients:read': true,
        'clients:create': true,
        'clients:update': true,
        'clients:delete': true,
        'clients:update-status': true,

        'clients:reset-password': true,
        'clients:invite': true,
        'clients:invite:for-dist': true
    },
    
    distributor: {
        'dashboard:load': true,
        'login-page:visit': true,

        'return:create': true,

        'orders:read': true,
        'orders:create': true,
        'orders:create:own': true,
        
        'orders:cancel': (user, order) => {
            return user._id && order.userId && user._id == order.userId && order.status == 'INIT';
        },

        'clients:invite': true,
        'clients:invite:own': true,
        'clients:read': true,

        'orders:return': (user, order) => {
            return user._id && order.userId && user._id == order.userId && order.status == 'DELIVERED';
        },
        'contact': true
    },
    client: {
        'dashboard:load': true,
        'login-page:visit': true,

        'return:create': true,
        
        'orders:read': true,
        'orders:create': true,
        'orders:create:own': true,

        'orders:return': (user, order) => {
            return user._id && order.userId && user._id == order.userId && order.status == 'DELIVERED';
        },
        
        'orders:cancel': (user, order) => {
            return user._id && order.userId && user._id == order.userId && order.status == 'INIT';
        },
        'contact': true
    },
    moderator: {
        'dashboard:load': true,
        'login-page:visit': true,
        
        'orders:read': true,
        
        // 'orders:create': true,
        // 'orders:create:for-client': true,

        'orders:update': true,
        'orders:validate': true,
        'orders:reject': true,
        
        'orders:export:create': true,
        'orders:export:read': true,
        
        'clients:read': true,
        // 'contact': true
        // 'clients:create': true,
        // 'clients:update': true,
        // 'clients:delete': true,
        // 'clients:update-status': true,

        // 'clients:reset-password': true,
        // 'clients:invite': true,
        // 'clients:invite:for-dist': true
    }
}

export default rules;



