import { createStore, applyMiddleware, compose } from "redux";



import thunk from 'redux-thunk';
import rootReducer from '../_reducers';


const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const middleware = [thunk];





export default createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));