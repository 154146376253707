const types = {
	CLIENTS_FETCH_REQUEST: "CLIENTS_FETCH_REQUEST",
	CLIENTS_FETCH_SUCCESS: "CLIENTS_FETCH_SUCCESS",
	CLIENTS_FETCH_FAILURE: "CLIENTS_FETCH_FAILURE",

	CLIENTS_ADD_REQUEST: "CLIENTS_ADD_REQUEST",
	CLIENTS_ADD_FAILURE: "CLIENTS_ADD_FAILURE",
	CLIENTS_ADD_SUCCESS: "CLIENTS_ADD_SUCCESS",

	CLIENTS_PATCH_REQUEST: "CLIENTS_PATCH_REQUEST",
	CLIENTS_PATCH_FAILURE: "CLIENTS_PATCH_FAILURE",
	CLIENTS_PATCH_SUCCESS: "CLIENTS_PATCH_SUCCESS",

	CLIENTS_DELETE_REQUEST: "CLIENTS_DELETE_REQUEST",
	CLIENTS_DELETE_FAILURE: "CLIENTS_DELETE_FAILURE",
	CLIENTS_DELETE_SUCCESS: "CLIENTS_DELETE_SUCCESS",

	CLIENTS_RESET_PASSWORD_REQUEST: "CLIENTS_RESET_PASSWORD_REQUEST",
	CLIENTS_RESET_PASSWORD_FAILURE: "CLIENTS_RESET_PASSWORD_FAILURE",
	CLIENTS_RESET_PASSWORD_SUCCESS: "CLIENTS_RESET_PASSWORD_SUCCESS",

	CLIENTS_INVITE_REQUEST: "CLIENTS_INVITE_REQUEST",
	CLIENTS_INVITE_SUCCESS: "CLIENTS_INVITE_SUCCESS",
	CLIENTS_INVITE_FAILURE: "CLIENTS_INVITE_FAILURE",

	FILE_UPLOAD_REQUEST: "FILE_UPLOAD_REQUEST",
	FILE_UPLOAD_SUCCESS: "FILE_UPLOAD_SUCCESS",
	FILE_UPLOAD_FAILURE: "FILE_UPLOAD_FAILURE",

	GET_MEDIA_REQUEST: "GET_MEDIA_REQUEST",
};

const clientsActions = {
	types,

	clientsFetchRequest: () => ({ type: types.CLIENTS_FETCH_REQUEST }),
	clientsFetchSuccess: (clients) => ({
		type: types.CLIENTS_FETCH_SUCCESS,
		payload: clients,
	}),
	clientsFetchFailure: (clients) => ({
		type: types.CLIENTS_FETCH_FAILURE,
		payload: clients,
	}),

	clientsAddRequest: () => ({ type: types.CLIENTS_ADD_REQUEST }),
	clientsAddSuccess: (client) => ({
		type: types.CLIENTS_ADD_SUCCESS,
		payload: client,
	}),
	clientsAddFailure: (errors) => ({
		type: types.CLIENTS_ADD_FAILURE,
		payload: { errors },
	}),

	clientsInviteRequest: () => ({ type: types.CLIENTS_INVITE_REQUEST }),
	clientsInviteSuccess: (client) => ({
		type: types.CLIENTS_INVITE_SUCCESS,
		payload: client,
	}),
	clientsInviteFailure: (errors) => ({
		type: types.CLIENTS_INVITE_FAILURE,
		payload: { errors },
	}),

	fileUploadRequest: () => ({ type: types.FILE_UPLOAD_REQUEST }),
	fileUploadSuccess: (client) => ({
		type: types.FILE_UPLOAD_SUCCESS,
		payload: client,
	}),
	fileUploadFailure: (errors) => ({
		type: types.FILE_UPLOAD_FAILURE,
		payload: { errors },
	}),
	getMediaRequest: () => ({ type: types.FILE_UPLOAD_REQUEST }),
	
	clientsPatchRequest: () => ({ type: types.CLIENTS_PATCH_REQUEST }),
	clientsPatchSuccess: (client) => ({
		type: types.CLIENTS_PATCH_SUCCESS,
		payload: client,
	}),
	clientsPatchFailure: (errors) => ({
		type: types.CLIENTS_PATCH_FAILURE,
		payload: { errors },
	}),

	clientsDeleteRequest: () => ({ type: types.CLIENTS_DELETE_REQUEST }),
	clientsDeleteSuccess: (user_id) => ({
		type: types.CLIENTS_DELETE_SUCCESS,
		payload: { _id: user_id },
	}),
	clientsDeleteFailure: (err) => ({
		type: types.CLIENTS_DELETE_FAILURE,
		err,
	}),

	clientsResetPasswordRequest: () => ({
		type: types.CLIENTS_RESET_PASSWORD_SUCCESS,
	}),
	clientsResetPasswordSuccess: ({ _id }) => ({
		type: types.CLIENTS_RESET_PASSWORD_SUCCESS,
		payload: { _id },
	}),
	clientsResetPasswordFailure: (err) => ({
		type: types.CLIENTS_RESET_PASSWORD_FAILURE,
		err,
	}),
};
export default clientsActions;
