import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const GenericSidebar = ({sections}) => {
    return (
        <div className="sidebar-sticky">
            {sections.map( (s, index) => {
                return (   
                                <Fragment key={index}>
                                    <Divider title={s.title} />
                                    <Navigation links={s.links} />
                                </Fragment>
                );
            })}
      </div>
 
    );
} 

const Navigation = ({links}) => {
    return (
        <ul className="nav flex-column">
            {links.map( (l) => (<NavItem {...l} key={l.href} title={l.title} href={l.href} />))}
        </ul>
        );

}
const NavItem = ({title, href, icon, type, target, active = false}) => {
    return (
        type == 'a' ?
        <li className="nav-item">
            <a className={"nav-link" + (active ? " active": "")} target={target} href={href}>
                <span data-feather={icon}></span>
                {title}
            </a>
        </li>
            :
         <li className="nav-item">
            <Link className={"nav-link" + (active ? " active": "")} to={href}>
                <span data-feather={icon}></span>
                {title}
            </Link>
        </li>
    );
}
const Divider = ({title, icon = null}) => {
    return (
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>{ title }</span>
          <a className="d-flex align-items-center text-muted" href="#" aria-label="Add a new report">
            <span data-feather={icon}></span>
          </a>
        </h6>
    );
}
export default GenericSidebar;
