import { overlayActions } from '../_actions';
const initialState = {
    showOverlay: false
};
const types = overlayActions.types;
const overlayReducer = (state = initialState, action) => {
    switch(action.type){
        case types.OVERLAY_SHOW:
            return {showOverlay: true};
            break;
        case types.OVERLAY_HIDE:
            return {showOverlay: false};
            break;
        default:
            return state;
    }
}
export default overlayReducer;