import React, { Fragment, Component } from 'react';
import { PageTop } from '../../layouts';
import { GenericForm } from '../../components';
import { addClientForm } from './add-client-form';
import { clientsCreators } from '../../_creators';
import { generatePassword } from '../../utils';
import { connect } from 'react-redux';

import './client-add.css';
import { ReduxAlert } from '../../containers';
class ClientAddPage extends Component{
    constructor(props){
        super(props);
        // this.generateRandomPassword = this.generateRandomPassword.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
           form:{
                values: addClientForm.defaultValues,
                errors: {},
                loading: false,
                error: false
           }
        };
    }
    componentWillReceiveProps(nextProps){
        if(this.props.errors != nextProps.errors){
            let { errors } = nextProps;
            if(errors && Object.keys(errors).length > 0) {
                this.setState((state) => ({form: {...state.form, errors: addClientForm.errorsFromBackend(errors)}}));
            }
            else {
                this.setState((state) => ({form: {...state.form, errors: {}}}))
            }
        }
    }
    handleChange(group, input, value){
        this.setState((state) => {
            let form = {...state.form};    
            form.values = GenericForm.updateGroups(form.values, {group, input, value});
            // let validators = addClientForm.validators(form.values);

            // let errors = GenericForm.validateInput(
            //     {group, input, value}, 
            //     validators[group][input]);

            // form.errors = GenericForm.updateErrors(form.errors, {
            //     group,
            //     input,
            //     errors
            // });
            // form.error = GenericForm.countErrors(form.errors);
            return {form};
        });
    }

    handleBlur(group, input, value){

        // this.setState((state) => ({form: {...state.form, loading: true}}));
        // this.setState( (state) => {
        //         let form = {...state.form};
        //         let validators = addClientForm.validators(form.values);
        //         let errors = GenericForm.validateInput(
        //                 {group, input, value: form.values[group] ? form.values[group][input] : null}, 
        //                 validators[group] && validators[group][input] ? validators[group][input] : null);

        //         form.errors = GenericForm.updateErrors(form.errors, {
        //             group,
        //             input,
        //             errors
        //         });
        //         form.error = GenericForm.countErrors(form.errors);
        //         return {form};
        //     });  
    }
    handleSubmit(e){
        e.preventDefault();
        let {errors, error} = GenericForm.validateForm(this.state.form.values, addClientForm.validators(this.state.form.values));
        if(errors && Object.keys(errors).length > 0) this.setState(({form}) => ({form: {...form, errors}}));
        else {
            this.setState(({form}) => ({form: {...form, errors: {}}}), () => {
                this.props.postClient(GenericForm.extractValues({groups: this.state.form.values}, addClientForm.extractors));
            });
        }
        // this.props.postClient(GenericForm.extractValues({groups: this.state.form.values}, addClientForm.extractors));
        
    }
    // generateRandomPassword(){
    //     this.setState( (state) => {
    //         let group = {...state.groups[0], password: generatePassword()};
    //         let groups = [...state.groups];
    //         groups.splice(0, 1, group);
    //         return {groups};
    //     });
    // }

    render(){
        let form = addClientForm(this.props.clients.filter( (c) => c.roles == 'distributor' ));
        let stateForm = this.state.form;
        // form.groups.inputs.generate.onClick = (e) => this.generateRandomPassword();
        return (
        <Fragment>
            <PageTop actions={[]} title="Ajouter un client"/>
            <ReduxAlert />
            <GenericForm 
  
                name="addClientForm"
                id="addClientForm"

                groups={form.groups}
                values={stateForm.values}
                errors={stateForm.errors}

                onChange={ this.handleChange }
                onBlur={ this.handleBlur }
                onSubmit={ this.handleSubmit }

                submit={{title: 'Save', className: 'btn btn-primary btn-block', disabled: stateForm.error }}
            />
        </Fragment>);
    }
}

const mapStateToProps = (state) => ({
    clients: state.clients.clients, 
    loading: state.clients.loading,
    errors: state.clients.errors
});
const mapDispatchToProps = (dispatch) => ({
    postClient: (user) => clientsCreators.create(user)(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientAddPage);