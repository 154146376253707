import config from "../../config";
const loginForm = {
	groups: {
		userdetails: {
			name: "userdetails",
			wrapper: "p",
			inputs: {
				email: {
					name: "email",
					type: "text",
					// required: true,
					wrapper: "p",
					disabled: false,
					placeholder: "ex: contact@elbeytechnologies.com",
				},
				password: {
					name: "password",
					type: "password",
					// required: true,
					wrapper: "p",
					disabled: false,
					placeholder: "Votre mot de passe",
				},
			},
		},
	},
	validators: {
		userdetails: {
			email: [
				(value) =>
					value &&
					value.length > 0 &&
					/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
						value
					)
						? false
						: "Please enter your email",
			],
			password: [
				(value) =>
					value && value.length >= 8
						? false
						: "Password should be at least 8 charachters",
			],
		},
	},
	extractors: {
		userdetails: {
			value: ({ email, password }) => ({ email, password }),
			inputs: {
				email: (value) => value,
				password: (value) => value,
			},
		},
	},
	defaultValues: {
		userdetails: {
			email: config.login.email,
			password: config.login.password,
		},
	},
};

export default loginForm;
