import React, { Component } from 'react';
import { ReduxProtectedGenericForm } from '../../containers';
import { GenericForm } from '../../components';

import { orderFiltersForm } from './order-filters-form';

import './order-filters.css';

class OrdersFilters extends Component{

    

    constructor(props){
        super(props);   
        this.handleChange = this.handleChange.bind(this);

        this.handleSave = this.handleSave.bind(this);

        this.state = {
            form:{
                values: orderFiltersForm.defaultValues(),
                errors: {},
                error: false, 
                loading: false
            }
        }
    }

   componentWillReceiveProps(nextProps){
       
       this.setState((state) => {
            var form = {...state.form};
            form.values.filters = {...form.values.filters};

            Object.keys(nextProps.filters).map( (filter) => {
                if(form.values.filters[filter] != nextProps.filters[filter]) form.values.filters[filter] = nextProps.filters[filter];   
            });
            return {form};
       });

   }

    handleChange(group, input, value){

        this.setState((state) => {
            let form = {...state.form};    

            form.values = GenericForm.updateGroups(form.values, {group, input, value});
            return {form};
        });
    }

    handleBlur(group, input, value){
        
    }
    
    handleSave(e){
        // // console.log('Saving...');
        // console.log(GenericForm.extractValues({groups: this.state.form.values}, loginForm.extractors));
        this.props.onSave(this.state.form.values.filters);
    }
    render(){
        let stateForm = this.state.form;

        let form = orderFiltersForm({
                        values: stateForm.values, 
                        clients: this.props.clients, 
                        onFilter: this.handleSave,
                        disabled: this.props.disabled
                    });
        
        return (
            <div id="order-filters">
                <ReduxProtectedGenericForm 
                            wrapper={'div'}
                            groups={form.groups}

                            // defaultValues={form.defaultValues}

                            values={stateForm.values}
                            errors={stateForm.errors}


                            onChange={ this.handleChange }
                            onBlur={ this.handleBlur }
                            
                        />
            </div>
        );
    }
}

export default OrdersFilters;