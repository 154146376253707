import { authActions } from "../_actions/";
// Weirdly enought this isnt working
// import { userStorage } from "../_helpers";
import userStorage from "../_helpers/user-storage";

const types = authActions.types;
const user = userStorage.get();
const initialState = user ? { loggingIn: false, user } : {user: null, loggingIn: false};

const authReducer = (state = initialState, action) => {
    switch(action.type){
        case types.LOGIN_REQUEST:
            return {
                loggingIn: true
            }
            break;
        case types.LOGIN_SUCCESS:
            return {
                loggingIn: false,
                user: action.payload
            }
            break;
        case types.LOGIN_FAILURE:
            return {
                loggingIn: false,
                user: null
            }
            break;
        case types.LOGOUT_SUCCESS:
            return {
                loggingIn: false,
                user: null
            }
            break;
        default:
            return state;
    }
}
export default authReducer;