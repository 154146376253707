import generatePassword from '../utils/generate-password';
import {
    checkAbility,
    checkAuthorization
} from './check-authorization';

import history from './history';
import userStorage from './user-storage';

import axios from './axios-instance';

export {
    userStorage,
    checkAbility,
    checkAuthorization,
    axios,
    history
}