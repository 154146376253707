import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { GenericInput } from "../../components";
import { ReduxProtectedPageTop, ReduxAlert } from "../../containers";
// import orders from '../../5ea87520e03db12e94863fb5';
// import { Order } from '../../models';
import config from "../../config";
import { clientsCreators } from "../../_creators";

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.handleOnChange = this.handleOnChange.bind(this);

		this.state = {
			gender: null,
			auth: null,
		};

		// orders is the list of orders

		// orders.map((order) => {

		//     order = Order.clean(order, 'SIMPLE'); // cleaning the order
		//     order.items = order.items.reduce((acc, item) => [...acc, ...Order.seperateSides(item)], []); // this splits one item into two items
		//     order.items = order.items.map((item) => Order.removeSidePrefix(item));

		// });
	}

	handleOnChange(name, value) {
		this.setState({
			[name]: value,
		});
	}

	render() {
		let { user } = this.props;
		sessionStorage.setItem("user_name", this.props.user.name);

		return (
			<Fragment>
				<ReduxAlert />
				<div className="mt-3">
					<h1>
						Bienvenue sur Cornelia CRM{" "}
						<i>{user.name}</i>
					</h1>
					<hr />
					<br />
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, null)(Dashboard);
