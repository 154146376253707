import { authActions, alertsActions } from "../_actions";

import { authService } from "../_services/";
import { userStorage, history } from "../_helpers";

const authCreators = {
	login: (user) => (dispatch) => {
		dispatch(authActions.loginRequest());
		dispatch(alertsActions.clearAll());

		return authService
			.login(user)
			.then((res) => res.data.data)
			.then((res) => {
				// Comment this line out in production
				// res = {...res, role: 'admin'};
				res = { ...res, role: res.roles[0] };

				userStorage.set(res);

				dispatch(authActions.loginSuccess(res));
				dispatch(alertsActions.clearAll());

				history.push("/order");
			})
			.catch((err) => {
				dispatch(authActions.loginFailure(err));
				// dispatch(alertsActions.push([{message: err.message, type: 'danger'}]));
			});
	},

	logout: () => (dispatch) => {
		dispatch(authActions.logoutRequest());
		return authService
			.logout()
			.then((res) => {
				dispatch(authActions.logoutSuccess());
				// dispatch(alertsActions.push([{message: 'Déconnexion avec success, a bientot', type: 'success'}]));
			})
			.catch((err) => {
				dispatch(authActions.logoutFailure());
			});
	},

	resetPassword:
		(client_email, admin = false) =>
		(dispatch) => {
			dispatch(authActions.clientsResetPasswordRequest());

			return authService
				.resetPassword(client_email)
				.then((res) => {
					dispatch(
						authActions.clientsResetPasswordSuccess({
							email: client_email,
						})
					);

					if (!admin) {
						history.push("/reset/success");
						history.go(0);
					}
				})
				.catch((err) => {
					console.log(err);
					dispatch(
						authActions.clientsResetPasswordFailure()
					);
				})
				.then((res) => {});
		},

	changePassword: (password, token) => (dispatch) => {
		dispatch(authActions.clientsChangePasswordRequest());

		return authService
			.changePassword(password, token)
			.then((res) => {
				dispatch(authActions.clientsChangePasswordSuccess({}));

				history.push("/login");
			})
			.catch((err) => {
				dispatch(authActions.clientsChangePasswordFailure());
				console.log(err);
			})
			.then((res) => {});
	},
};

export default authCreators;
