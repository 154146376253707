import React from 'react';
import { checkAbility } from '../../_helpers';
// console.log( JSON.stringify(wilayas.map( (w) => ({label: w.value + ' - ' + w.label, value: w.value}) )) )
const halfCol = {tag: 'div', attrs: {className: 'col-sm-6'}}
const inviteClientForm = (clients) => {
    return {
        name: "inviteClientForm",
        id: "inviteClientForm",
        actions: "",
        method: "",
        groups: {
            "client_details":{
                name: 'client_details',
                wrapper: {tag: 'div', attrs: {className: 'row'}},
                inputs: {
                    "email": {wrapper: halfCol, name: 'email',  label: 'Email', type: 'text'},
                    "partnerId": {wrapper: halfCol, name: 'partnerId', label: 'Distributeur', 
                        restricted: 'clients:invite:for-dist',type: 'select', values: [{ label: 'None', value: '' },
                        ...clients.map((c) => ({ label: c.name, value: c._id }))] },
                   }
            }
        },
    };
}

inviteClientForm.errorsFromBackend = (errs) => {
    let errors = {};
    let groups = {
        client_details: ["email", "partnerId"]
    };
    return Object.keys(groups).reduce((newGroups, groupIndex) => {
        let group = groups[groupIndex];

        return {...newGroups, [groupIndex]: group.reduce( (acc, i) => {
            if(errs[i]){
                return {...acc, [i]: errs[i]}
            }
            return acc;
        }  , {})};
    }, {});
};
inviteClientForm.validators = (user, {clients, values}) => {
    return {
        client_details:{
            email: [
                (value) => 
                    value && value.length > 0 && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ? false : 'Vous devez remplir ce champ avec un email valid',
            ],
            partnerId: [
                (value) => {
                            let possibleValues = [];
                            
                            if(!value || (value && value.length) < 1) return 'Vous devez choisir un distributor';
        
                            if(checkAbility(user, 'clients:invite:own')) possibleValues.push(user._id);
        
                            if(checkAbility(user, 'clients:invite:for-dist')) {
                                possibleValues = possibleValues.concat(clients.map(({_id}) => _id));
                            }
                            return possibleValues.indexOf(value) > -1 ? false : 'Vous devez choisir un distributor';
                    }
                ],
        }
    };
};
inviteClientForm.extractors = {
    client_details:{
        value: (group) => group,
        inputs: {}
    }
};

inviteClientForm.defaultValues = (user) => ({
    client_details:{
        email: '',
        partnerId: checkAbility(user, 'clients:invite:for-dist') ? '' : user._id,
    }
});

export {
    inviteClientForm
}