import React, { Fragment, Component } from 'react';
import { PageTop } from '../../layouts';
import { GenericForm } from '../../components';
import { inviteClientForm } from './invite-client-form';
import { clientsCreators } from '../../_creators';

import { connect } from 'react-redux';

import './client-invite.css';
import { ReduxAlert, ReduxProtectedGenericForm } from '../../containers';
class ClientInvitePage extends Component{

    constructor(props){
        super(props);
        // this.generateRandomPassword = this.generateRandomPassword.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
           form:{
                values: inviteClientForm.defaultValues(this.props.user),
                errors: {},
                loading: false,
                error: false
           }
        };
    }
    componentWillReceiveProps(nextProps){
        if(this.props.errors != nextProps.errors){
            let { errors } = nextProps;
            if(errors && Object.keys(errors).length > 0) {
                this.setState((state) => ({form: {...state.form, errors: inviteClientForm.errorsFromBackend(errors)}}));
            }
            else {
                this.setState((state) => ({form: {...state.form, errors: {}}}))
            }
        }
    }
    handleChange(group, input, value){
        this.setState((state) => {
            let form = {...state.form};    
            form.values = GenericForm.updateGroups(form.values, {group, input, value: value.trim()});
            // let validators = addClientForm.validators(form.values);

            // let errors = GenericForm.validateInput(
            //     {group, input, value}, 
            //     validators[group][input]);

            // form.errors = GenericForm.updateErrors(form.errors, {
            //     group,
            //     input,
            //     errors
            // });
            // form.error = GenericForm.countErrors(form.errors);
            return {form};
        });
    }

    handleBlur(group, input, value){

    }
    handleSubmit(e){
        e.preventDefault();
        let {errors, error} = GenericForm.validateForm(this.state.form.values, 
                                inviteClientForm.validators(this.props.user, 
                                            {values: this.state.form.values, clients: this.props.clients.filter( (c) => c.roles == 'distributor' )}));

        if(errors && Object.keys(errors).length > 0) this.setState(({form}) => ({form: {...form, errors}}));
        else {
            this.setState(({form}) => ({form: {...form, errors: {}}}), () => {
                this.props.inviteClient(GenericForm.extractValues({groups: this.state.form.values}, inviteClientForm.extractors));
                console.log('Invite ', this.state.form.values);
            });
        }

    }

    render(){

        let form = inviteClientForm(this.props.clients.filter( (c) => c.roles == 'distributor' ));
        let stateForm = this.state.form;
        // form.groups.inputs.generate.onClick = (e) => this.generateRandomPassword();
        return (
        <Fragment>
            <PageTop actions={[]} title="Inviter un client"/>
            <ReduxAlert />
            <ReduxProtectedGenericForm 
  
                name="addClientForm"
                id="addClientForm"

                groups={form.groups}
                values={stateForm.values}
                errors={stateForm.errors}

                onChange={ this.handleChange }
                onBlur={ this.handleBlur }
                onSubmit={ this.handleSubmit }

                submit={{title: 'Save', className: 'btn btn-primary btn-block', disabled: stateForm.error }}
            />
        </Fragment>);
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    clients: state.clients.clients, 
    loading: state.clients.loading,
    errors: state.clients.errors
});
const mapDispatchToProps = (dispatch) => ({
    inviteClient: ({email, partnerId}) => clientsCreators.invite({email, partnerId})(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientInvitePage);