import React, { Fragment } from 'react';

import GenericInputGroup from '../_generic-input-group';

const GenericForm = (props) => {
    let { 
        name, id, className, onSubmit, submit,
        
        groups, 
        values, 
        defaultValues,
        errors, 

        onChange, 
        onBlur, 
        wrapper
    } = props;
    let FormWrapper = wrapper ? wrapper : 'form';

    return (
     
            <FormWrapper className={className ? className : 'form'} name={name} id={id} onSubmit={onSubmit}>
                {
                    Object.keys(groups).map((groupIndex, index) => {
                        let group = groups[groupIndex];

                        
                     
                        let groupValues = values && values[groupIndex] ? values[groupIndex] : null;
                        let groupDefaultValues = defaultValues && defaultValues[groupIndex] ? defaultValues[groupIndex] : null;
                        let groupErrors = errors && errors[groupIndex] ? errors[groupIndex] : null;
                        return (
                            //
                                <GenericInputGroup 
                                        key={groupIndex} 
                                        {...group} 

                                        onChange={ (input, value) => onChange(groupIndex, input, value) } 
                                        onBlur={ (input, value) => onBlur(groupIndex, input, value) }

                                        errors={groupErrors}
                                        values={groupValues}
                                        defaultValues={groupDefaultValues}
                                />
                            
                        );
                    })
                }
                {submit && <button {...submit}>{submit.title}</button>}
            </FormWrapper>
  
    );
}

// updates the values of the form (one input)
GenericForm.updateGroups = (oldGroups, {group, input, value}) => {

        let newGroups = {...oldGroups};
      
        newGroups[group] = {...oldGroups[group], [input]: value};

        return newGroups;
}
// updates the errors of the form (one input blured)
GenericForm.updateErrors = (oldErrors, {group, input, errors}) => {
    // if(!errors || errors.length < 1) return oldErrors;

    let newErrors = {...oldErrors};
    
    newErrors[group] = {
        ...newErrors[group],
        [input]: errors
    };
    return newErrors;
}

// validates a specific input
GenericForm.validateInput = ({group, input, value}, validators = null) => {
    if(!validators || validators.length < 1) return null;

    let errors = validators.reduce( (acc, error) => {
        error = error(value);
        if(error) return [...acc, error];
        return acc;
    }, []);

    return errors;
}

GenericForm.validateForm = (groups, validators = null) => {
    let validation = {errors: {}, error: false};
    if(!validators || validators.length < 1) return validation;

    validation.errors = Object.keys(validators).reduce((acc, groupIndex) => {
        let groupValidators = validators[groupIndex];
        
        if(!groupValidators || Object.keys(groupValidators).length < 1) return acc;
        
        let errors = Object.keys(groupValidators).reduce((acc, inputIndex) => {
            let inputValidators = groupValidators[inputIndex];            
            if(!inputValidators || Object.keys(inputValidators).length < 1) return acc;
            
            let errors =  GenericForm.validateInput(
                {group: groupIndex, input: inputIndex, value: groups[groupIndex] ? groups[groupIndex][inputIndex] : null}, 
                inputValidators);

            if(errors && errors.length > 0) {
                validation.error = validation.error || true; 
                return {...acc, [inputIndex]: errors};
            };

            return acc;
        }, {});

        if(errors && Object.keys(errors).length > 0) return {...acc, [groupIndex]: errors};
        return acc;
    }, {});

    return validation;
}

GenericForm.extractValues = ({groups}, extractors) => {
    if(!extractors || Object.keys(extractors).length < 1) return groups;

    return Object.keys(groups).reduce((acc, groupIndex) => {
        let group = groups[groupIndex];

        let groupExtractors = extractors[groupIndex];

        if(groupExtractors){

            if(groupExtractors.inputs && Object.keys(groupExtractors.inputs).length > 0){

                group = Object.keys(groupExtractors.inputs).reduce((acc, inputIndex) => {
                    let input = group[inputIndex];
                    let inputExtractor = groupExtractors.inputs[inputIndex];

                    if(inputExtractor){
                        input = inputExtractor(input);
                    }

                    return {...acc, [inputIndex]: input};
                }, {});

            }

            group = groupExtractors.value ? groupExtractors.value(group) : group;
        }
        return {...acc, ...group};
    },{});   

}

GenericForm.countErrors = (errors) => {
    if(Object.keys(errors).length == 0 ) return false;

    return Object.keys(errors).reduce((acc, group) => {
        group = errors[group];
        if(Object.keys(group).length == 0) return acc;

        return acc || Object.keys(group).reduce((acc, input) => acc || (group[input] && group[input].length > 0), false);
    }, false);
}
export default GenericForm;
