import config from "../config";
import authService from "./auth";

import { axios } from "../_helpers";
import { jsonToUrl } from "../utils";

const ordersService = {
	checkDuplicate: (order) =>
		axios({
			overlay: false,
			url: config.routes.orders.check,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: order,
				infos: {},
			},
		}),
	fetch: (filters = {}, page = 1, per_page = 10) =>
		axios({
			url: `${
				config.routes.orders.fetch
			}?page=${page}&per_page=${per_page}${jsonToUrl(filters)}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}),
	create: (orders) =>
		axios({
			url: `${config.routes.orders.create}/group`,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: { data: orders },
		}),
	patch: (order, action) =>
		axios({
			url: `${config.routes.orders.patch}`,
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: order,
				infos: {
					action,
				},
			},
		}),
	patchGroup: (orders, action = "orders:update") =>
		axios({
			url: `${config.routes.orders.patch}/group`,
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: orders,
				infos: {
					action,
				},
			},
		}),
	export: ({ orders, file }) =>
		axios({
			url: `${config.routes.orders.export}`,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: {
					orders,
					file,
				},
				infos: {
					action: "orders:export",
				},
			},
		}).then((res) => {
			res.data.data.path = `${config.uploads}/${res.data.data.path}`;
			return Promise.resolve(res);
		}),
	exportAll: () =>
		axios({
			url: `${config.routes.orders.export}/all`,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: {},
				infos: {
					action: "orders:export",
				},
			},
		}).then((res) => {
			res.data.data.path = `${config.uploads}/${res.data.data.path}`;
			return Promise.resolve(res);
		}),
	exportPdf: (orderId) =>
		axios({
			url: `${config.routes.orders.export}/pdf/${orderId}`,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: {
					orderId,
				},
				infos: {
					action: "orders:export",
				},
			},
		}).then((res) => {
			res.data.data.path = `${config.uploads}/${res.data.data.path}`;
			return Promise.resolve(res);
		}),

	exportPdfKeepStatus: (orderId) =>
		axios({
			url: `${config.routes.orders.export}/pdf/keepStatus/${orderId[0]}`,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				data: {
					orderId,
				},
				infos: {
					action: "orders:export",
				},
			},
		}).then((res) => {
			res.data.data.path = `${config.uploads}/${res.data.data.path}`;
			return Promise.resolve(res);
		}),

	fetchExports: (filters = {}, page = 1, per_page = 10) =>
		axios({
			url: `${
				config.routes.orders.export
			}?page=${page}&per_page=${per_page}${jsonToUrl(filters)}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}),
};

export default ordersService;
