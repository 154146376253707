import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
	ReduxProtectedGenericTable,
	ReduxProtectedPageTop,
} from "../../containers";
import { Pagination, OrdersExportFilters } from "../../components";
import { orderExportFiltersForm } from "../../components/order-export-filters/order-export-filters-form";
import { orderExportTable } from "./order-export-table";
import { ordersCreators } from "../../_creators";
import config from "../../config";
class OrderExportPage extends Component {
	constructor(props) {
		super(props);

		this.handleSelectLine = this.handleSelectLine.bind(this);
		this.handleSelectPage = this.handleSelectPage.bind(this);

		this.exportSelected = this.exportSelected.bind(this);
		this.exportAll = this.exportAll.bind(this);
		this.exportOne = this.exportOne.bind(this);

		this.fetchOrders = this.fetchOrders.bind(this);
		this.fetchPage = this.fetchPage.bind(this);
		this.applyFilters = this.applyFilters.bind(this);

		this.state = {
			...orderExportFiltersForm.defaultValues(), // will give filters:{}
			orders: [],
			pagination: {},
		};
	}

	componentWillMount() {
		this.fetchOrders(this.state.filters, 1).then((res) => {
			this.setState({
				orders: res.data.map((o) => ({
					...o,
					selected: false,
				})),
				pagination: res.pagination,
			});
		});
	}

	fetchOrders(filters = {}, page = 1) {
		let query = Object.keys(filters).reduce((acc, filter) => {
			if (filter.indexOf("date") > -1 || filters[filter] == "")
				return acc;
			return { ...acc, [filter]: filters[filter] };
		}, {});

		query.type = "SIMPLE";
		query.status = "VALIDATED";

		query.createdAt = {};

		if (
			filters.starting_date &&
			filters.starting_date instanceof Date
		) {
			filters.starting_date.setHours(0, 0, 0);
			query.createdAt.$gte = filters.starting_date.getTime();
		}

		if (filters.ending_date && filters.ending_date instanceof Date) {
			// filters.ending_date.setDate(filters.ending_date.getDate() );
			filters.ending_date.setHours(23, 59, 59);

			query.createdAt.$lte = filters.ending_date.getTime();
		}

		query = JSON.stringify(query);

		return this.props.fetchOrders(
			{ base64: true, query: btoa(query) },
			page
		);
	}

	fetchPage(page) {
		this.fetchOrders(this.state.filters, page).then((res) => {
			this.setState({
				orders: res.data.map((o) => ({
					...o,
					selected: false,
				})),
				pagination: res.pagination,
			});
		});
	}

	applyFilters(filters) {
		this.fetchOrders(filters, 1).then((res) => {
			this.setState({
				filters,
				orders: res.data.map((o) => ({
					...o,
					selected: false,
				})),
				pagination: res.pagination,
			});
		});
	}

	handleSelectLine(index, _id, selected) {
		this.setState(({ orders }) => {
			orders = [...orders];
			orders[index] = { ...orders[index], selected };
			return { orders };
		});
	}

	exportSelected() {
		let orders = this.state.orders
			.filter(({ selected }) => selected)
			.map(({ _id }) => _id);

		this.props
			.exportOrders({
				orders,
				file: {
					extention: "json",
					content: JSON.stringify(orders),
				},
			})
			.then((res) => {
				// window.open(res.path);
				this.fetchPage(1);
			});
	}

	exportAll() {
		this.props.exportAll().then((res) => {
			//   window.open(res.path);
			this.fetchPage(1);
		});
	}

	exportOne(line) {
		this.props
			.exportOrders({
				orders: [line._id],
				file: {
					extention: "json",
					content: JSON.stringify([line._id]),
				},
			})
			.then((res) => {
				// window.open(res.path);
				this.fetchPage(1);
			});
	}

	handleSelectPage(selected) {
		this.setState(({ orders }) => ({
			orders: [...orders].map((o) => ({ ...o, selected })),
		}));
	}

	render() {
		let { orders, pagination } = this.state;

		pagination = {
			...pagination,
			current: pagination.page,
		};

		let table = orderExportTable({
			clients: this.props.clients,
			onSelectLine: this.handleSelectLine,
			selectPage: this.handleSelectPage,
			exportOne: this.exportOne,
			isPageSelected:
				orders.filter(({ selected }) => selected).length ==
				orders.length,
			status: config.status,
		});
		return (
			<Fragment>
				<ReduxProtectedPageTop
					actions={[
						{
							text: `Exporter (${
								orders.filter(
									({ selected }) => selected
								).length
							})`,
							onClick: (e) => this.exportSelected(),
							restricted: "orders:export:create",
							color: "btn-info",
						},

						{
							text: `Exporter Tout (${
								pagination.total
									? pagination.total
									: "0"
							})`,
							onClick: (e) => this.exportAll(),
							restricted: "orders:export:create",
							color: "btn-info",
						},
					]}
					title="Exporter les commandes"
				/>
				<OrdersExportFilters
					onSave={this.applyFilters}
					filters={this.state.filters}
					// disabled={this.props.loading}

					clients={this.props.clients}
				/>

				<ReduxProtectedGenericTable
					cols={table.cols}
					data={orders}
				/>

				<Pagination onClick={this.fetchPage} {...pagination} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	clients: state.clients.clients,
});

const mapDispatchToProps = (dispatch) => ({
	fetchOrders: (filters = {}, page = 1) =>
		ordersCreators.fetch(filters, page)(dispatch),
	exportOrders: ({ orders, file }) =>
		ordersCreators.export({ orders, file })(dispatch),
	exportAll: () => ordersCreators.exportAll()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderExportPage);
