import React, { Fragment } from "react";
import { Order } from "../../models";
import "./order-details.css";
const OrderDetails = ({ order }) => {
	order = Order.toString(order);
	if (order.type == "MULTIPLE") return "#";
	return (
		<div className="order-details">
			{order.items.map((item, index) => (
				<ul key={index}>
					{item.map((side, index) => (
						<li key={index}>{side}</li>
					))}
				</ul>
			))}
			{order.blue && (
				<small style={{ color: "#007bff" }}>Bleutée</small>
			)}
		</div>
	);
};

export default OrderDetails;
