import React, { Component } from 'react';

import './patch-row.css';

const PatchRow = ({status, selectedStatus, onStatusChange, disabled, onPatch}) => {
    return (
        <div className="row">
            <div className="col">
                <select value={selectedStatus} 
                        onChange={ (e) => onStatusChange(e.target.value) } 
                        name="status" className="form-control form-control-sm">
                        {Object.keys(status).map( (s, index) => <option key={index} value={s}>{status[s]}</option> )}
                </select>
            </div>
            <div className="col">
                <button disabled={ disabled } 
                        onClick={ (e) =>  onPatch() } 
                        className="btn btn-success btn-sm">Patcher</button>    
            </div>
        </div>
    )
}

export default PatchRow;