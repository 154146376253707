import config from "../../config";
const passwordChangeForm = {
	groups: {
		userdetails: {
			name: "userdetails",
			wrapper: "p",
			inputs: {
				password: {
					name: "password",
					type: "password",
					// required: true,
					wrapper: "p",
					disabled: false,
					placeholder: "Nouveau mot de passe",
				},
				confirmPassword: {
					name: "confirmPassword",
					type: "password",
					// required: true,
					wrapper: "p",
					disabled: false,
					placeholder:
						"Confirmez Votre Nouveau mot de passe",
				},
			},
		},
	},
	validators: {
		userdetails: {
			password: [
				(value) =>
					value && value.length >= 8
						? false
						: "Password should be at least 8 charachters",
			],
			confirmPassword: [
				(value) =>
					value && value.length >= 8
						? false
						: "Password should be at least 8 charachters",
			],
		},
	},
	extractors: {
		userdetails: {
			value: ({ password, confirmPassword }) => ({
				password,
				confirmPassword,
			}),
			inputs: {
				password: (value) => value,
				confirmPassword: (value) => value,
			},
		},
	},
	defaultValues: {
		userdetails: {},
	},
};

export default passwordChangeForm;
