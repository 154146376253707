import React, {Component} from 'react';
import { connect } from 'react-redux';
import { checkAuthorization } from '../../_helpers';
import { GenericFormTable } from '../../components';

class ReduxProtectedGenericFormTable extends Component{
    static updateGroups(oldGroups, {group, input, value}){
        return GenericFormTable.updateGroups(oldGroups, {group, input, value});
    }
    render(){
        let { cols, user, data, ...props } = this.props;
        cols = Object.keys(cols).reduce( (acc, col) => {
            col = cols[col];

            if(col.restricted && !checkAuthorization(user, col.restricted, {})){
                return acc;
            }

            return {...acc, [col.name]: col};

        }, {});
        return (
            <GenericFormTable  {...props} cols={cols} data={data}/>
        );
    }

}



const mapStateToProps = (state) => ({user: state.auth.user});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxProtectedGenericFormTable);