import React, {Component} from 'react';
import { connect } from 'react-redux';
import { checkAbility, checkAuthorization } from '../../_helpers';
import { GenericTable } from '../../components';
class ReduxProtectedGenericTable extends Component{
    render(){
        let { cols, user, data, ...props } = this.props;

        let protectedCols = Object.keys(cols).reduce( (acc, col) => {
            col = {...cols[col]};
            
            if(!col.restricted) return {...acc, [col.name]: col};

            if(!checkAbility(user, col.restricted, {})){
                return acc;
            }
            
            col.content = (line, index) =>  checkAuthorization(user, col.restricted, line) ? cols[col.name].content(line, index) : null;

            return {...acc, [col.name]: col};

        }, {});
        
        return (
            <GenericTable  {...props} cols={protectedCols} data={data}/>
        );
    }
}



const mapStateToProps = (state) => ({user: state.auth.user});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxProtectedGenericTable);