import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

const OrderListPage = lazy(() => import("./pages/order-list/"));
const OrderListStockPage = lazy(() => import("./pages/order-list-stock/"));
const OrderAddPage = lazy(() => import("./pages/order-add/"));
const OrderAddStockPage = lazy(() => import("./pages/order-add-stock"));

const ReturnAddPage = lazy(() => import("./pages/return-add/"));

const OrderSearchPage = lazy(() => import("./pages/order-search"));

const OrderExportPage = lazy(() => import("./pages/order-export/"));
const OrderExportStockPage = lazy(() => import("./pages/order-export-stock"));

const OrderExportListPage = lazy(() => import("./pages/order-export-list/"));

const ClientListPage = lazy(() => import("./pages/client-list/"));
const ClientEditPage = lazy(() => import("./pages/client-edit/"));
const ClientAddPage = lazy(() => import("./pages/client-add/"));

const ClientInvitePage = lazy(() => import("./pages/client-invite/"));

const Dashboard = lazy(() => import("./pages/dashboard/"));
const DashboardMedia = lazy(() => import("./pages/dashboard-media/"));

export default [
	{
		path: "/order/stock/add",
		exact: true,
		component: OrderAddStockPage,
		restricted: "orders:create",
	},

	{
		path: "/return/add",
		exact: true,
		component: ReturnAddPage,
		restricted: "return:create",
	},

	{
		path: "/order/add",
		exact: true,
		component: OrderAddPage,
		restricted: "orders:create",
	},
	{
		path: "/order/search/:searchq",
		exact: true,
		component: OrderSearchPage,
		restricted: "orders:read",
	},

	{
		path: "/order/export/history",
		exact: true,
		component: OrderExportListPage,
		restricted: "orders:export:read",
	},
	{
		path: "/order/export",
		exact: true,
		component: OrderExportPage,
		restricted: "orders:export:create",
	},

	{
		path: "/order/stock/export",
		exact: true,
		component: OrderExportStockPage,
		restricted: "orders:export:create",
	},
	{
		path: "/order/stock",
		exact: true,
		component: OrderListStockPage,
		restricted: "orders:read",
	},

	{
		path: "/order",
		exact: true,
		component: OrderListPage,
		restricted: "orders:read",
	},

	{
		path: "/client/add",
		exact: true,
		component: ClientAddPage,
		restricted: "clients:create",
	},
	{
		path: "/client/invite",
		exact: true,
		component: ClientInvitePage,
		restricted: "clients:invite",
	},
	{
		path: "/client/:client_id/edit",
		exact: true,
		component: ClientEditPage,
		restricted: "clients:update",
	},
	{
		path: "/client",
		exact: true,
		component: ClientListPage,
		restricted: "clients:read",
	},

	{
		path: "/",
		exact: true,
		component: Dashboard,
		restricted: "dashboard:load",
	},
	{
		path: "/media",
		exact: true,
		component: DashboardMedia,
		restricted: "dashboard:load",
	},

	{
		path: "*",
		exact: true,
		component: (props) => <Redirect to="/" />,
		restricted: "dashboard:load",
	},
];
