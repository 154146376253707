import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { ordersService } from "../../_services";

import { alertsActions } from "../../_actions";

import {
	ReduxProtectedGenericTable,
	ReduxProtectedPageTop,
	ReduxAlert,
} from "../../containers";

import {
	Pagination,
	OrdersFilters,
	GenericForm,
	PatchRow,
} from "../../components";
import { orderFiltersForm } from "../../components/order-filters/order-filters-form";
import { history } from "../../_helpers";
import { orderListTableStock } from "./order-list-table-stock";

import "./order-list-stock.css";
import overlayActions from "../../_actions/overlay";
import { ordersCreators } from "../../_creators";
import { arrayToObject } from "../../utils";
import config from "../../config";

class OrderListStockPage extends Component {
	constructor(props) {
		super(props);

		// this.validateOrder = this.validateOrder.bind(this);

		this.handleSelectLine = this.handleSelectLine.bind(this);
		this.handleSelectPage = this.handleSelectPage.bind(this);

		this.fetchOrders = this.fetchOrders.bind(this);

		this.handleStatusChange = this.handleStatusChange.bind(this);

		this.exportOne = this.exportOne.bind(this);
		this.rejectOrder = this.rejectOrder.bind(this);
		this.cancelOrder = this.cancelOrder.bind(this);
		this.patchGroup = this.patchGroup.bind(this);

		this.fetchPage = this.fetchPage.bind(this);
		this.applyFilters = this.applyFilters.bind(this);
		this.handleGetDetails = this.handleGetDetails.bind(this);

		this.handleReturnOrder = this.handleReturnOrder.bind(this);

		this.actions = [
			{
				text: "Ajouter",
				onClick: (e) => history.push("/order/stock/add"),
				restricted: "orders:create",
				color: "btn-primary",
			},
			{
				text: "Exporter",
				onClick: (e) => history.push("/order/stock/export"),
				restricted: "orders:export:create",
				color: "btn-info",
			},
		];

		this.state = {
			...orderFiltersForm.defaultValues(), // will give filters:{}
			orders: [],
			pagination: {},
			// selected_orders: [],
			selected_status: "VALIDATED",
		};
	}

	componentWillMount() {
		this.fetchOrders(this.state.filters, 1).then((res) => {
			this.setState({
				orders: res.data.map((o) => ({
					...o,
					selected: false,
				})),
				pagination: res.pagination,
			});
		});
	}

	fetchOrders(filters = {}, page = 1) {
		let query = Object.keys(filters).reduce((acc, filter) => {
			if (filter.indexOf("date") > -1 || filters[filter] == "")
				return acc;
			return { ...acc, [filter]: filters[filter] };
		}, {});
		query.type = "MULTIPLE";
		query.createdAt = {};

		if (
			filters.starting_date &&
			filters.starting_date instanceof Date
		) {
			filters.starting_date.setHours(0, 0, 0);
			query.createdAt.$gte = filters.starting_date.getTime();
		}

		if (filters.ending_date && filters.ending_date instanceof Date) {
			// filters.ending_date.setDate(filters.ending_date.getDate() );
			filters.ending_date.setHours(23, 59, 59);

			query.createdAt.$lte = filters.ending_date.getTime();
		}

		query = JSON.stringify(query);
		console.log(query);
		return this.props.fetchOrders(
			{ base64: true, query: btoa(query) },
			page
		);
	}

	fetchPage(page) {
		this.fetchOrders(this.state.filters, page).then((res) => {
			this.setState({
				orders: res.data.map((o) => ({
					...o,
					selected: false,
				})),
				pagination: res.pagination,
			});
		});
	}

	handleGetDetails(_id) {
		this.props.fetchExports({ orders: _id }).then((res) => {
			let data = res.data;
			if (data.length > 0) {
				data = data[0];
				window.open(data.path);
			} else {
				alert("Cette commande n'a pas un document exporté");
			}
		});
	}

	applyFilters(filters) {
		this.fetchOrders(filters, 1).then((res) => {
			this.setState({
				filters,
				orders: res.data.map((o) => ({
					...o,
					selected: false,
				})),
				pagination: res.pagination,
			});
		});
	}

	handleSelectLine(index, _id, selected) {
		this.setState(({ orders }) => {
			orders = [...orders];
			orders[index] = { ...orders[index], selected };
			return { orders };
		});
	}

	handleStatusChange(value) {
		this.setState((state) => ({ selected_status: value }));
	}

	exportOne(_id) {
		this.props.exportPdf(_id).then((res) => {
			window.open(res.path);
			this.fetchPage(1);
		});
	}

	rejectOrder(_id) {
		let message = window.prompt("SVP Ajouter un commentaire");
		if (message != null) {
			this.props.rejectOrder(_id, message).then((res) => {
				this.applyFilters(this.state.filters);
			});
		}
	}

	handleReturnOrder(_id) {
		this.props.returnOrder(_id).then((res) => {
			this.applyFilters(this.state.filters);
		});
	}

	cancelOrder(_id) {
		if (window.confirm("Etes vous sur?")) {
			this.props.cancelOrder(_id).then((res) => {
				this.applyFilters(this.state.filters);
			});
		}
	}

	handleSelectPage(selected) {
		this.setState(({ orders }) => {
			orders = [...orders].map((o) => ({ ...o, selected }));
			return { orders };
		});
	}

	patchGroup(status = null) {
		status = status ? status : this.state.selected_status;

		let orders = this.state.orders
			.filter(({ selected }) => selected)
			.map(({ _id }) => ({ _id, status }));

		this.props.patchOrdersGroup(orders).then(() => {
			this.applyFilters(this.state.filters); // fetch a new list with the applied filters
		});
	}

	render() {
		let { clients } = this.props;
		let { pagination, orders } = this.state;

		let table = orderListTableStock({
			status: config.status,
			clients: arrayToObject(this.props.clients, "_id"),
			onSelectLine: this.handleSelectLine,
			onSelectPage: this.handleSelectPage,
			isPageSelected:
				this.state.orders.filter(({ selected }) => selected)
					.length == this.state.orders.length,
			onExport: this.exportOne,
			onReject: this.rejectOrder,
			onCancel: this.cancelOrder,
			onGetDetails: this.handleGetDetails,
			onReturn: this.handleReturnOrder,
		});

		// console.log(pagination);
		pagination = {
			...pagination,
			current: pagination.page,
		};

		return (
			<Fragment>
				<ReduxProtectedPageTop
					actions={this.actions}
					title="Historique des commandes (Stock)"
				/>
				<ReduxAlert />
				<div className="alert alert-warning">
					Note importante pour nos client:{" "}
					<a
						href="https://drive.google.com/file/d/1jk5e-vfg566nZBvWFJ5W7BENFy2rBb2d/view?usp=sharing"
						target="_blank"
					>
						consulter la note
					</a>
				</div>
				<OrdersFilters
					onSave={this.applyFilters}
					filters={this.state.filters}
					// disabled={this.props.loading}

					clients={this.props.clients}
				/>
				<div className="float-left" id="group-actions">
					<PatchRow
						status={config.status}
						selectedStatus={this.state.selected_status}
						onStatusChange={this.handleStatusChange}
						disabled={
							orders.filter(
								({ selected }) => selected
							).length < 1
						}
						onPatch={this.patchGroup}
					/>
				</div>
				<br />
				<br />
				<ReduxProtectedGenericTable
					fallback={
						this.props.loading
							? (props) => (
									<div className="spinner-border text-primary"></div>
							  )
							: null
					}
					cols={table.cols}
					data={orders}
				/>

				<div id="bottom-page">
					<div className="float-left" id="group-actions">
						<PatchRow
							status={config.status}
							selectedStatus={
								this.state.selected_status
							}
							onStatusChange={
								this.handleStatusChange
							}
							disabled={
								orders.filter(
									({ selected }) => selected
								).length < 1
							}
							onPatch={this.patchGroup}
						/>
					</div>

					<div className="float-right">
						<Pagination
							onClick={this.fetchPage}
							{...pagination}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state) => ({
	orders: state.orders.orders.map((o) => ({ ...o, selected: false })),
	pagination: state.orders.pagination,
	loading: state.orders.loading,
	clients: state.clients.clients.filter(
		(c) => ["client", "distributor"].indexOf(c.roles) > -1
	),
});
const mapDispatchToProps = (dispatch) => ({
	fetchOrders: (filters = {}, page = 1) =>
		ordersCreators.fetch(
			{ type: "MULTIPLE", ...filters },
			page
		)(dispatch),
	patchOrdersGroup: (orders) => ordersCreators.patchGroup(orders)(dispatch),
	exportPdf: (orderId) => ordersCreators.exportPdf(orderId)(dispatch),
	rejectOrder: (_id, comment) =>
		ordersCreators.patch({ _id, status: "REJECTED", comment })(
			dispatch
		),
	cancelOrder: (_id) =>
		ordersCreators.patch({ _id, status: "CANCELED" })(dispatch),
	fetchExports: (filters) => ordersCreators.fetchExports(filters)(dispatch),
	returnOrder: (_id) =>
		ordersCreators.patch({ _id, status: "RETURN" })(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderListStockPage);
