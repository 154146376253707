import Axios from 'axios';
import { overlayActions, alertsActions } from '../_actions';
import { userStorage } from './';
import store from '../_store';
import history from './history';
const axios = Axios.create({});

axios.interceptors.request.use((config) => {
    if(config.overlay == undefined || config.overlay){
        store.dispatch(overlayActions.show());
    }
    let user = userStorage.get();
    if(user){
        config.headers = {
            ...config.headers,
            'x-auth-token': `${user.token}`     
        }
    }
    return config;
}, (error) => {
    store.dispatch(overlayActions.hide());
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    store.dispatch(overlayActions.hide());
    return Promise.resolve(response)
}, (error) => {
    let err = {};

    if(error.response){

        // we recieved a response from server
        let { data, status } = error.response;

        let errors, message;
        switch(status){
            case 400: // bad request    
                message = data.message ? data.message : 'Server responded with: Bad request';
                errors = data.error ? data.error : {}; 
                break;
            case 401: // (authorized) unauthenticated (disaptch a logout)
                message = data.message ? data.message : 'Server responded with: unauthenticated';
                userStorage.unset();
                history.push('/login');
                break;
            case 403: // unauthorized (no permissions) (disaptch a global error)
                message = data.message ? data.message : 'Server responded with: not-permitted';
                break;
            case 404:
                message = data.message ? data.message : 'Server responded with: Not found';
                break;
            case 500:
                message = data.message ? data.message : 'Server responded with: Server error';
                break;
        }
        store.dispatch(alertsActions.push([{message, type: 'danger'}]));
        // extract messages from errors.details
        if(errors && errors.details && errors.details.length > 0){
            errors = errors.details.reduce((acc, e) => {
                return {...acc, [e.path[0]]: [e.message]};
            }, {});
        }

        // adapt errors to the GenericForm format
        // if(errors && Object.keys(errors).length > 0) {
        //     errors = Object.keys(errors).reduce((acc, input) => {
        //         return {...acc, [input]: [errors[input]]};
        //     }, {});
        // }

        err = {
            message,
            errors,
            status
        };
    }else{
        err = {
            message: 'Server didnt respond',
            status: 500
        };
    }
    store.dispatch(overlayActions.hide());
    return Promise.reject(err); 
});

export default axios;