import { clientsActions } from "../_actions";
const initialState = {
    clients: [],
    pagination: {},
    errors: null,
    loading: false
};
const types = clientsActions.types;

const clientsReducer = (state = initialState, action) => {
    switch(action.type){
        case types.CLIENTS_FETCH_SUCCESS:
            return {
                loading: false, clients: action.payload
            };
            break;
        case types.CLIENTS_ADD_REQUEST:
        case types.CLIENTS_PATCH:
        case types.CLIENTS_FETCH_REQUEST:
            return {
                ...state,
                errors: null,
                loading: true
            };
            break;
        case types.CLIENTS_ADD_SUCCESS:
            return {
                loading: false, clients: [...state.clients, action.payload]
            };
            break;
        case types.CLIENTS_ADD_FAILURE: 
        case types.CLIENTS_PATCH_FAILURE: 
        case types.CLIENTS_INVITE_FAILURE: 
            return {
                ...state, loading: false, errors: action.payload.errors
            };
        case types.CLIENTS_PATCH_SUCCESS:
            
            let clients = [...state.clients];
            let index = clients.findIndex((c) => c._id == action.payload._id); // client index
            clients.splice(index, 1, action.payload);

            return {
                loading: false, clients
            };
            break;
        case types.CLIENTS_DELETE_SUCCESS:
            return {
                clients: [...state.clients.filter( (c) => c._id != action.payload._id )], loading: false
            }
            break;
        default:
            return state;
            break;
    }
}

export default clientsReducer;