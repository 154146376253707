import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ReduxAlert } from "../../containers";
import { GenericForm } from "../../components";

// import { authActions } from '../../_actions';
import { authCreators } from "../../_creators";
import passwordChangeForm from "./password-change-form";

import "./password-change.css";
class PasswordChange extends Component {
	constructor(props) {
		super(props);
		this.props.logout();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);

		this.state = {
			form: {
				values: passwordChangeForm.defaultValues,
				errors: {},
				error: false,
				loading: false,
				token: this.props.token,
			},
		};
	}

	handleChange(group, input, value) {
		this.setState((state) => {
			let form = { ...state.form };
			if (input == "email") value = value.toLowerCase();
			form.values = GenericForm.updateGroups(form.values, {
				group,
				input,
				value: value.trim(),
			});
			return { form };
		});
	}

	handleBlur(group, input, value) {}
	handleSubmit(e) {
		e.preventDefault();

		let { errors, error } = GenericForm.validateForm(
			this.state.form.values,
			passwordChangeForm.validators
		);

		if (errors && Object.keys(errors).length > 0) {
			this.setState(({ form }) => ({
				form: { ...form, errors, error },
			}));
		} else {
			e.preventDefault();

			let password = GenericForm.extractValues(
				{ groups: this.state.form.values },
				passwordChangeForm.extractors
			);

			this.props.changePassword(password, this.state.form.token);
		}
	}
	render() {
		let stateForm = this.state.form;
		return (
			<div className="form-signin">
				<h1 className="h3 mb-3 font-weight-normal">
					Inserez Votre Nouveau Mot de Passe
				</h1>
				<ReduxAlert />
				<GenericForm
					groups={passwordChangeForm.groups}
					defaultValues={passwordChangeForm.defaultValues}
					values={stateForm.values}
					errors={stateForm.errors}
					onChange={this.handleChange}
					onBlur={this.handleBlur}
					onSubmit={this.handleSubmit}
					submit={{
						title: "Confirmer",
						className: "btn btn-primary btn-block",
						disabled: this.props.auth.loggingIn,
					}}
				/>
				<small className="mt-5 mb-3 text-muted">
					Attention: en vous connectant, vous accepter les{" "}
					<a
						target="_blank"
						href="https://www.corneliacontactalgerie.com/"
					>
						Condition d'utilisation
					</a>
				</small>
				<p className="mt-5 mb-3 text-muted text-center">
					&copy; Cornelia Contact Algeria 2020 | Designed
					and Developed by{" "}
					<a
						target="_blank"
						href="http://elbeytechnologies.com/"
					>
						ELBEY Technologies
					</a>
				</p>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
	changePassword: (password, token) =>
		authCreators.changePassword(password, token)(dispatch),
	logout: () => authCreators.logout()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
