import React, { Component } from 'react';
import { connect } from 'react-redux';
import { alertsCreators } from '../../_creators';

import './redux-alert.css';

class ReduxAlert extends Component{
    constructor(props){
        super(props);
    }
    render(){
        let { alerts } = this.props;
        if(alerts && alerts.length > 0){
            return <div id="alerts-wrapper">
                {
                    alerts.map( (alert, index) => {
                        return (
                            <div key={index} className={`alert alert-${alert.type}`}>
                                {alert.message}
                                <button className="close" onClick={ (e) => this.props.pop(alert.id) } type="button" className="close">
                                     <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        );
                    })
                }
            </div>
        }
        return null;
    }
}
const mapStateToProps = (state) => ({alerts: state.alerts});
const mapDispatchToProps = (dispatch) => ({
    pop: (id) => alertsCreators.pop(id)(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxAlert);