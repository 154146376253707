import React, { Suspense, Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { history } from "./_helpers";
import { DefaultLayout } from "./layouts";
import { LoginPage } from "./pages";
import { PasswordReset } from "./pages";
import { PasswordChange } from "./pages";
import { ReduxProtectedRoutes } from "./containers";
import routes from "./routes";
import config from "./config";
import * as Sentry from "@sentry/browser";
import "./dashboard.css";

if (config.environment != "developement") {
	Sentry.init({
		environment: config.environment,
		...config.sentry,
	});
}

class App extends Component {
	render() {
		let { auth } = this.props;
		return (
			<div className="App">
				<Router history={history}>
					<Switch>
						<Route
							path="/login"
							render={(props) => <LoginPage />}
						/>
						<Route
							path="/reset/:success?"
							render={(props) => (
								<PasswordReset
									success={
										props.match.params
											.success
									}
								/>
							)}
						/>
						<Route
							path="/change/:token"
							render={(props) => (
								<PasswordChange
									token={
										props.match.params
											.token
									}
								/>
							)}
						/>

						<DefaultLayout user={auth.user}>
							<Suspense
								fallback={<p>loading...</p>}
							>
								<ReduxProtectedRoutes
									routes={routes}
								/>
							</Suspense>
						</DefaultLayout>
					</Switch>
				</Router>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(App);
