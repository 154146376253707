import React from "react";
import { GenericForm } from "../../components";
import { OverlayTrigger, Tooltip, Overlay } from "react-bootstrap";
import { ReduxAuthorizedComponent } from "../../containers";
import { Order } from "../../models";
import { date, arrayToObject } from "../../utils";

const orderListTableStock = ({
	status,
	onGetDetails,
	clients,
	onSelectLine,
	onReturn,
	onSelectPage,
	onExport,
	onReject,
	onCancel,
	isPageSelected,
}) => {
	return {
		cols: {
			selected: {
				name: "selected",
				label: ((props) => (
					<input
						type="checkbox"
						checked={isPageSelected}
						onChange={(e) =>
							onSelectPage(e.target.checked)
						}
					/>
				))(),
				content: (line, index) => (
					<input
						checked={line.selected}
						type="checkbox"
						onChange={(e) =>
							onSelectLine(
								index,
								line._id,
								e.target.checked
							)
						}
					/>
				),
			},
			order_id: {
				name: "order_id",
				label: "ID",
				content: (line) =>
					line._id.substr(line._id.length - 3, 3),
			},

			distributor: {
				name: "distributor",
				label: "Distributeur",
				content: (line, index) => {
					let orderUser = clients[line.userId];
					if (!orderUser) return "#";
					if (orderUser.roles == "distributor") {
						return orderUser.name;
					}
					return clients[orderUser.partnerId].name;
				},
				restricted: "clients:read",
			},

			client: {
				name: "client",
				label: "Client",
				content: (line, index) => {
					let orderUser = clients[line.userId];
					if (!orderUser) return "#";
					if (orderUser.roles == "distributor") {
						return "#";
					}
					return orderUser.name;
				},
				restricted: "clients:read",
			},

			date: {
				name: "date",
				label: "Date",
				content: (line) => date.time.toString(line.createdAt),
			},
			type: {
				name: "type",
				label: "Type",
				content: (line) => line.type,
			},
			norme: {
				name: "norme",
				label: "Norme",
				content: (line) => line.norm,
			},
			details: {
				className: "details-cell",
				name: "details",
				label: "Details",
				content: (line) => (
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							onGetDetails(line._id);
						}}
					>
						Details
					</a>
				),
			},
			qty: {
				name: "qty",
				label: "Qty",
				content: (line) => Order.qty(line),
			},
			status: {
				name: "status",
				label: "Etat",
				content: (line, index) => {
					if (line.status != "REJECTED")
						return status[line.status];
					return (
						<OverlayTrigger
							key={line._id}
							placement="bottom"
							overlay={
								<Tooltip
									id={`tooltip-${line._id}`}
								>
									{line.comment}
								</Tooltip>
							}
						>
							<span>{status[line.status]}</span>
						</OverlayTrigger>
					);
				},
			},
			print: {
				name: "print",
				label: "Imprimer",
				restricted: "orders:reject",
				content: (line, index) => (
					<button
						onClick={(e) => onExport(line._id)}
						className="btn btn-sm btn-success"
					>
						Imprimer
					</button>
				),
			},
			reject: {
				name: "reject",
				label: "Rejeter",
				restricted: "orders:reject",
				content: (line, index) => (
					<button
						onClick={(e) => onReject(line._id)}
						className="btn btn-sm btn-danger"
					>
						Rejeter
					</button>
				),
			},
			retour: {
				name: "retour",
				label: "Retour",
				restricted: "orders:return",
				content: (line, index) => (
					<button
						onClick={(e) => {
							e.preventDefault();
							onReturn(line._id);
						}}
						className="btn btn-sm btn-warning"
					>
						Retour
					</button>
				),
			},
			cancel: {
				name: "cancel",
				label: "Annulation",
				restricted: "orders:cancel",
				content: (line) => {
					return (
						<ReduxAuthorizedComponent
							restricted="orders:cancel-order"
							data={{ order: line }}
							yes={(props) => (
								<button
									disabled={
										line.fetching ||
										line.status ==
											"CANCELED" ||
										line.status !=
											"INIT"
									}
									onClick={(e) =>
										onCancel(line)
									}
									className="btn btn-danger btn-sm"
								>
									Annuler
								</button>
							)}
						/>
					);
				},
			},
		},
	};
};

export { orderListTableStock };
