import rules from '../rules';

const able = (rules, user, restricted) => {
    // check if there's a user
    if(!user) return false;

    // check if restricted is empty (in case we just want him to be authenticated)
    if(restricted == '') return true;

    
    // check if  the user has a  specific role
    const permissions = rules[user.role];
    if(!permissions) return false;

    const staticPermissions = Object.keys(permissions);
    // check if he has the ability
    if(staticPermissions && staticPermissions.indexOf(restricted) > -1) {
        // console.log({able: restricted});
        return true
    };

    return false;
}
const authorized = (rules, user, restricted, data) =>  {
      // check if there's a user
      if(!user) return false;

      // check if restricted is empty (in case we just want him to be authenticated)
      if(restricted == '') return true;
  
      
      // check if  the user has a  specific role
      const permissions = rules[user.role];
      if(!permissions) return false;
  
      const staticPermissions = Object.keys(permissions);
      // check if he has the ability
      if(!staticPermissions || staticPermissions.indexOf(restricted) < 0) return false;

      if(typeof(permissions[restricted]) == 'boolean') return permissions[restricted];

      return permissions[restricted](user, data)

}
const checkAbility = (user, restricted) => able(rules, user, restricted);

const checkAuthorization = (user, restricted, data) => authorized(rules, user, restricted, data);


export {
    checkAbility,
    checkAuthorization
};