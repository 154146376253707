import React from 'react';
import { GenericInput } from '../';
const GenericInputGroup = ({ inputs, onChange, onBlur, values, defaultValues, errors, wrapper, ...rest }) => {

    let GroupWrapper = wrapper && wrapper.tag ? wrapper.tag : 'p'; 
    let wrapperAttrs = wrapper &&  wrapper.attrs ? wrapper.attrs : {}; 

    return (
        <GroupWrapper {...wrapperAttrs}>
            {Object.keys(inputs).map( (input, index) => {
                let inputValue = values && values[input] != undefined ? values[input] : 
                                                    (defaultValues && defaultValues[input] != undefined ? defaultValues[input] : null);
                let inputErrors = errors && errors[input] ? errors[input] : null;

                return (
                        <GenericInput 
                                
                                value={inputValue}
                                
                                errors={inputErrors} 

                                onChange={(value) => onChange(input, value)} 
                                
                                onBlur={ (value) => onBlur(input, value)} 
                                
                                {...inputs[input]} 
                                
                                key={index}
                            />);
            } )}                       
        </GroupWrapper>
    );
}
export default GenericInputGroup;
