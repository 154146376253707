export default {
	sections: [
		{
			title: "Dashboard",
			restricted: "dashboard:load",
			links: [
				{
					href: "/",
					title: "Dashboard",
					restricted: "dashboard:load",
				},
				
			],
		},
		{
			title: "Initiation",
			restricted: "orders:create",
			links: [
				{
					href: "/order/add",
					title: "Ajouter une commande",
					restricted: "orders:create",
				},
				{
					href: "/order/stock/add",
					title: "Ajouter une commande (Stock)",
					restricted: "orders:create",
				},
				{
					href: "/return/add",
					title: "Ajouter un retour",
					restricted: "return:create",
				},
			],
		},
		{
			title: "Historique",
			restricted: "orders:read",
			links: [
				{
					href: "/order",
					title: "Liste des commandes",
					restricted: "orders:read",
				},
				{
					href: "/order/stock",
					title: "Liste des commandes (Stock)",
					restricted: "orders:read",
				},
			],
		},
		{
			title: "Exporation",
			restricted: "orders:export:read",
			links: [
				{
					href: "/order/export",
					title: "Exporter les commandes",
					restricted: "orders:export:create",
				},
				{
					href: "/order/stock/export",
					title: "Imprimer les commandes (Stock)",
					restricted: "orders:export:create",
				},
				{
					href: "/order/export/history",
					title: "Historique d'exportation",
					restricted: "orders:export:read",
				},
			],
		},
		{
			title: "Clients",
			restricted: "clients:read",
			links: [
				{
					href: "/client",
					title: "Liste des clients",
					restricted: "clients:read",
				},
				{
					href: "/client/add",
					title: "Ajouter un client",
					restricted: "clients:create",
				},
			],
		},
		{
			title: "Profile",
			restricted: "dashboard:load",
			links: [
				{
					type: "a",
					target: "_blank",
					href: "https://www.corneliacontactalgerie.com/contact.html?email=",
					title: "Contactez nous",
					restricted: "contact",
				},
				{
					href: "/login",
					title: "Déconnexion",
					restricted: "login-page:visit",
				},
			],
		},
	],
};
