import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import { clientsCreators, ordersCreators } from "../_creators";

// import { GenericSidebar } from './../components';
// import { ReduxProtectedGenericForm } from '../containers';
import TopNavbar from "./partials/top-navbar";
import sidebarData from "./partials/sidebar";
import { OverlayBackground } from "../components";
import { checkAuthorization, history } from "../_helpers";
import { extractSearch, extractQuery } from "../utils";
import { Order } from "../models";
// import { ordersService } from '../_services';

import { ReduxProtectedSidebar } from "../containers/";
import cloneDeep from "lodash.clonedeep";
import "./default-layout.css";

class DefaultLayout extends Component {
	constructor(props) {
		super(props);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this);

		this.state = {
			searchq: "",
		};
	}
	componentWillMount() {
		if (checkAuthorization(this.props.user, "clients:read", {})) {
			this.props.fetchClients();
		}
	}

	handleSearchChange(name, value) {
		this.setState((state) => ({ [name]: value }));
	}

	handleSearchSubmit(e) {
		e.preventDefault();

		history.push("/order/search/" + this.state.searchq);
		this.setState((state) => ({ ...state, searchq: "" }));
		// console.log('Searching...');

		// let search = Order.extractSearch(form.norms, this.state.searchq);

		// if(search){
		//     console.log(search);
		//     this.props.searchOrders(Order.extractQuery(search, 'search'));
		// }else{
		//     alert("Le format n'est pas conforme au regles");
		// }
	}

	render() {
		let { children, user, overlay } = this.props;
		// console.log('DefaultLayout: render ', {user, sidebarData});
		return (
			<Fragment>
				{overlay.showOverlay && <OverlayBackground />}
				<TopNavbar
					searchq={this.state.searchq}
					onSearchSubmit={this.handleSearchSubmit}
					onSearchChange={this.handleSearchChange}
					user={user}
				/>

				<div className="container-fluid" id="main-container">
					<div className="row">
						<nav className="col-md-2 bg-light-blue">
							<ReduxProtectedSidebar
								sidebar={cloneDeep(sidebarData)}
							/>
						</nav>

						<main
							role="main"
							className="col-md-9 ml-sm-auto col-lg-10 px-4"
						>
							{children}
						</main>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({ overlay: state.overlay });
const mapDispatchToProps = (dispatch) => ({
	fetchClients: (page = 1, filters = {}) =>
		clientsCreators.fetch(page, filters)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
