import React from 'react';
import { date, arrayToObject } from '../../utils';
import { Order } from '../../models';
const orderExportTableStock = ({status, clients, exportOne}) => {
    return {
        cols: {
            // selected: {
            //         name: 'selected', 
            //         label: ((props) => <input checked={isPageSelected}  type="checkbox" onChange={ (e) =>  selectPage(e.target.checked) } />)(),
            //         content: (line, index) => <input checked={line.selected} type="checkbox" onChange={ (e) => onChange(line._id, e.target.checked)  } />
            //     },
            order_id: {name: "order_id", label: "#ID", content: (line) => line._id.substr(line._id.length - 3, 3) },
            // client: {
            //         name: "client", label: "Client", 
            //         content: (line, index) => {
            //             if(clients && clients.length > 0){
            //                 let user = clients.filter( (c) => c._id == line.userId )[0];
            //                 return user.name;
            //             }
            //             return '';
            //         }, restricted: 'clients:read'},
            distributor: {name: "distributor", label: "Distributeur", 
                content: (line, index) => {
                    let orderUser = arrayToObject(clients, '_id')[line.userId];
                    if(orderUser.roles == 'distributor'){
                        return orderUser.name;
                    }
                    return arrayToObject(clients, '_id')[orderUser.partnerId].name
                }, restricted: 'clients:read'},

            client: {name: "client", label: "Client", 
                content: (line, index) => {
                    let orderUser = arrayToObject(clients, '_id')[line.userId];
                        if(orderUser.roles == 'distributor'){
                            return "#";
                        }
                    return orderUser.name
                }, restricted: 'clients:read'},
            
            norme: {name: 'norme', label: 'Norme', content: (line) => line.norm},
            details: {className: 'details-cell' ,name: 'details', label: 'Details', content: (line) => <p>#</p>},
            qty: {name: 'qty', label: 'Qty', content: (line) => Order.qty({...line, type: 'MULTIPLE'})},
            status: {name: 'status', label: 'Etat', content: (line) => status[line.status]},
            date: {name: 'date', label: 'Date', content: (line) => date.time.toString(line.createdAt)},
            export : {name: 'export', label: 'Exporter', content: (line) => <button onClick={ (e) => exportOne(line) } className="btn btn-sm btn-info">Exporter</button>}
        }
    }
}

export {
    orderExportTableStock
}