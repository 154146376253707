const Order = {
	seperateSides: (item) => {
		return ["left", "right"].reduce((acc, side) => {
			let sideItems = Object.keys(item).filter(
				(p) => p.indexOf(side) > -1
			);
			if (sideItems.length > 0)
				return [
					...acc,
					sideItems.reduce(
						(acc, p, index) => ({
							...acc,
							[p]: item[p],
						}),
						{}
					),
				];
			return acc;
		}, []);
	},
	clean: (order, type) => {
		order = { ...order, items: [...order.items] };
		if (type == "SIMPLE") {
			order.items = order.items.map((item) => {
				if (!item.right_qty || item.right_qty == 0) {
					return Object.keys(item).reduce((acc, p) => {
						if (p.indexOf("right") < 0)
							return { ...acc, [p]: item[p] };
						return acc;
					}, {});
				}
				return item;
			});
		} else if (type == "MULTIPLE") {
			order.items = order.items.filter((item) => item.qty > 0);
		}
		return order;
	},
	toString: (order) => {
		return {
			...order,
			items: Order.clean(order, "SIMPLE").items.map((item) =>
				Order.seperateSides(item).map((side) =>
					Order.sideToString(order.norm, side)
				)
			),
		};
	},
	qty: (order) => {
		return Order.clean(order, order.type).items.reduce(
			(acc, item, index) => {
				if (order.type == "SIMPLE") {
					return (
						acc +
						Number.parseInt(
							Order.seperateSides(item).reduce(
								(acc, side, index) => {
									let qty = Object.keys(
										side
									).filter(
										(p) =>
											p.indexOf(
												"qty"
											) > -1
									)[0];
									if (!qty) return acc;
									qty = side[qty];
									return (
										acc +
										Number.parseInt(qty)
									);
								},
								0
							)
						)
					);
				} else {
					let qty = Object.keys(item).filter(
						(p) => p.indexOf("qty") > -1
					)[0];
					if (!qty) return acc;
					qty = item[qty];
					return acc + Number.parseInt(qty);
				}
			},
			0
		);
	},
	sideToString: (norm, side) => {
		// removing the side
		side = Object.keys(side).reduce(
			(acc, p) => ({ ...acc, [p.split("_")[1]]: side[p] }),
			{}
		);
		// return JSON.stringify(side);
		switch (norm) {
			case "AXELLE38":
			case "AXELLE38PRS":
			case "AXELLE70":
			case "AXELLE70PRS":
			case "VERDE":
			case "VERDEPLAN":
			case "GIALLO":
			case "GIALLOPLAN":
			case "SPICE":
			case "PERLA":
			case "PERLAPLAN":
			case "SPICEPLAN":
				return `${side.r} ${side.d} ${side.p} (${side.qty})`;
			case "KERA18":
			case "KERA36":
			case "KERA92":
			case "KERA100":
			case "DK18":
			case "DK36":
			case "DK92":
			case "DK100":
				if (side.e === "0.00" || side.e === "NaN") {
					return `${side.r} ${side.d} ${side.p} (${side.qty})`;
				} else {
					return `${side.r} ${side.d} ${side.p} (${side.qty})`;
				}

			case "TORIQUE38":
			case "TORIQUE70":
			case "TORIQUE38PRS":
			case "TORIQUE70PRS":
			case "AXELLETOR":
			case "VERDE TORIQUE":
			case "GIALLO TORIQUE":
			case "SPICE TORIQUE":
			case "PERLA TORIQUE":
				return `${side.r} ${side.d} ${side.sph} ${side.cyl} ${side.axe} (${side.qty})`;
			case "SCLERALE":
				return `${side.r} ${side.sag1} ${side.d} ${side.sph} (${side.qty})`;
			case "SCLERALE TORIQUE":
				return `${side.r} ${side.sag1} ${side.d} ${side.tor} ${side.sph} ${side.cyl} ${side.axe} (${side.qty})`;

			case "T.IN42":
			case "T.IN100":
			case "T.IN125":
				return `${side.r} ${side.r2} ${side.d} ${side.sph} (${side.qty})`;

			case "B42":
			case "B100":
			case "B125":
				return `${side.r} ${side.r2} ${side.d} ${side.sph} ${side.cyl} ${side.axe} (${side.qty})`;
			case "T.EXT42":
			case "T.EXT100":
			case "T.EXT125":
				return `${side.r} ${side.d} ${side.sph} ${side.cyl} ${side.axe} (${side.qty})`;

			default:
				return "NO NORM";
		}
	},
	extractSearch: (client, value) => {
		// regex expression https://regex101.com/r/kFPtfE/1
		//let regex = new RegExp(`^(\\w+ )?(${Object.keys(norms).join('|')}) (((\\-?[0-9]*[.])?[0-9]+\\s){2,4}(\\-?[0-9]*[.])?[0-9]+)$`);
		let regex = new RegExp(
			"^(((\\-?[0-9]*[.])?[0-9]+\\s)*((\\-?[0-9]*[.])?[0-9]+))$"
		);
		let matches = value.trim().match(regex);

		console.log(matches);

		if (matches && matches.length > 0) {
			matches = matches.splice(1, 3);
			let items = matches[0];

			// items = items.reduce((acc, i) => {...acc}, {});

			return { client, items: items };
		} else {
			return null;
		}
	},

	extractQuery: (order, type) => {
		if (!order.items) {
			return {};
		}
		let query = {};
		let items = order.items;
		if (type == "search") {
			// dont forget client code
			query = {
				$or: ["left", "right"].map((side) =>
					Object.keys(items[0]).reduce(
						(acc, p) => ({
							...acc,
							[`items.${side}_${p}`]: items[0][p],
						}),
						{}
					)
				),
			};
			if (order.client && order.client.length > 0)
				query["user.c_code"] = order.client;
		} else if (type == "check") {
			query = {
				norm: order.norm,
				$or: [],
			};
			if (order.userId && order.userId.length > 0)
				query.userId = order.userId;
			items.push({
				...Object.keys(items[0])
					.filter((i) => i.indexOf("left") > -1)
					.reduce(
						(acc, i, index) => ({
							...acc,
							[i.replace(
								"left",
								"right"
							)]: items[0][i],
						}),
						{}
					),
				...Object.keys(items[0])
					.filter((i) => i.indexOf("right") > -1)
					.reduce(
						(acc, i, index) => ({
							...acc,
							[i.replace(
								"right",
								"left"
							)]: items[0][i],
						}),
						{}
					),
			});

			query.$or = items.map((i) => {
				return Object.keys(i).reduce((acc, p, index) => {
					if (p.indexOf("qty") > -1) return acc;
					return { ...acc, [`items.${p}`]: i[p] };
				}, {});
			});
			return query;
			// suppose there's only one side
			// let present_sides = ['left'];

			// if(order.items[0].right_qty && order.items[0].right_qty != '') present_sides.push('right');

			// let current = items.left_qty && items.left_qty != 0 ? 'left' : 'right';
			// query = {
			//     norm: order.norm,
			//     $or: ['left', 'right'].map( (side) => Object.keys(items).reduce((acc, p) => {
			//         if (p.indexOf('qty') > -1) return acc;
			//         if(p.indexOf(current)){
			//             return ({...acc, [`items.${p}`]: items[p]})
			//         }else{
			//             p = p.split(`${current}_`)[1];
			//             return ({...acc, [`items.${side}_${p}`]:  items[`${current}_${p}`]})
			//         }
			//     } , {}))
			// }
			// if(order.client && order.client.length > 0) query.userId = order.client;
		}
		return query;
	},
	removeSidePrefix: (item) => {
		return Object.keys(item).reduce((acc, p) => {
			return { ...acc, [p.split("_")[1]]: item[p] };
		}, {});
	},
};

export default Order;
