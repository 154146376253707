import config from "../../config";
const passwordResetForm = {
	groups: {
		userdetails: {
			name: "userdetails",
			wrapper: "p",
			inputs: {
				email: {
					name: "email",
					type: "text",
					// required: true,
					wrapper: "p",
					disabled: false,
					placeholder: "ex: contact@elbeytechnologies.com",
				},
			},
		},
	},
	validators: {
		userdetails: {
			email: [
				(value) =>
					value &&
					value.length > 0 &&
					/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
						value
					)
						? false
						: "Please enter your email",
			],
		},
	},
	extractors: {
		userdetails: {
			value: ({ email }) => ({ email }),
			inputs: {
				email: (value) => value,
			},
		},
	},
	defaultValues: {
		userdetails: {
			email: config.login.email,
		},
	},
};

export default passwordResetForm;
