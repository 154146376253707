const types = {
    OVERLAY_SHOW: 'OVERLAY_SHOW',
    OVERLAY_HIDE: 'OVERLAY_HIDE',
}

const overlayActions = {
        types,

        show: () => ({type: types.OVERLAY_SHOW, payload: {}}),
        hide: () => ({type: types.OVERLAY_HIDE, payload: {}})
}

export default overlayActions;