import React from "react";
import addOrderForm from "../order-modal/add-order-form";
import { date } from "../../utils";
import config from "../../config";
let status = config.status;
const orderExportFiltersForm = ({
	clients = [],
	values,
	onFilter,
	disabled,
}) => {
	return {
		name: "orderFiltersForm",
		id: "orderFiltersForm",
		actions: "",
		method: "",
		groups: {
			filters: {
				wrapper: { tag: "div", attrs: { className: "row" } },
				name: "filters",
				restricted: "orders:read",
				inputs: {
					userId: {
						wrapper: {
							tag: "div",
							attrs: { className: "col-sm-4" },
						},
						name: "userId",
						label: "Client",
						type: "select",
						values: [
							{
								label: "Tous les clients",
								value: "",
							},
							...clients.map((c) => ({
								label: c.name,
								value: c._id,
							})),
						],
						restricted: "clients:read",
					},
					// "norm": {wrapper:{tag: 'div', attrs:{className: 'col-sm-4'}}, name: 'norm', label: 'Norme', type: 'select', values: [{label: 'Choisir une norme', value: ''}, ...Object.keys(addOrderForm.norms).map( (n) => ({label: n, value: n}) )] },
					// "status": {wrapper:{tag: 'div', attrs:{className: 'col-sm-4'}}, disabled: true,name: 'status', label: 'Etat', type: 'select', values: [{label: 'Choisir un etat', value: ''}, ...Object.keys(status).map((s) => ({label: status[s], value: s}))] },
					starting_date: {
						wrapper: {
							tag: "div",
							attrs: { className: "col-sm-4" },
						},
						name: "starting_date",
						type: "date",
						label: "Date (debut)",
					},
					ending_date: {
						wrapper: {
							tag: "div",
							attrs: { className: "col-sm-4" },
						},
						name: "ending_date",
						type: "date",
						label: "Date (fin)",
					},
					filter: {
						wrapper: {
							tag: "div",
							attrs: { className: "col-sm-4" },
						},
						disabled,
						name: "filter",
						text: "Filtrer",
						className: "btn btn-primary btn-block",
						onClick: onFilter,
						type: "button",
						label: ((props) => <span>&nbsp;</span>)(),
					},
				},
			},
		},
	};
};
const defaultValues = () => {
	console.log(new Date().getTime() - 3 * 24 * 60 * 1000);
	return {
		filters: {
			userId: "",
			// norm: '',
			// status: 'VALIDATED',
			starting_date: new Date(
				new Date().getTime() +
					config.filters.dates.start * 24 * 3600 * 1000
			), // days * hours per day * seconds per hour * milliseconds per second
			ending_date: new Date(
				new Date().getTime() +
					config.filters.dates.end * 24 * 3600 * 1000
			),
		},
	};
};

orderExportFiltersForm.defaultValues = defaultValues;

export { orderExportFiltersForm };
