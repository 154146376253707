import React, { Component } from "react";
import { connect } from 'react-redux';

import Authorized from '../../components/_authorized';

class ReduxAuthorizedComponent extends Component{
    render(){
        let { yes, no, restricted, data } = this.props;

        return (
            <Authorized 
                    yes={yes}
                    no={no}
                    user={this.props.auth.user}
                    restricted={restricted}
                    data={data}
            />
        );
    }
}

const mapStateToProps = (state) => ({auth: state.auth});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxAuthorizedComponent);