import React from 'react';
const Pagination = ({current = 1, total_pages, onClick}) => {

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className={current == 1 ? "page-item disabled" : "page-item"}><a className="page-link" onClick={(e) => onClick(1)} href="#">Premiere</a></li>
                {current > 1 && <li className="page-item"><a className="page-link" onClick={ (e) => onClick(current - 1) } href="#">{current - 1}</a></li>}

                <li className="page-item disabled"><a className="page-link" href="#">{current}</a></li>
                {current < total_pages && <li className="page-item"><a className="page-link" onClick={ (e) => onClick(current + 1) } href="#">{current + 1}</a></li>}
                <li className={total_pages == current ? "page-item disabled" : "page-item"}><a className="page-link" onClick={(e) => onClick(total_pages)} href="#">Derniere ({total_pages})</a></li>
            </ul>
        </nav>
    )
}

export default Pagination;